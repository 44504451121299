import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import Typography from "@material-ui/core/Typography/index";
import UserState from "../states/UserState";
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import ProjectContext from "../store";
import * as shared from "./stages/shared";
import {getBackendURL} from "../context/host";
import FormGroup from "@material-ui/core/FormGroup";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {DatePicker} from "@material-ui/pickers";
import {toast} from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const backendURL = getBackendURL();

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,

    inputNumber: {
        width: 150,
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    input: {
        width: 100,
        marginLeft: theme.spacing(2),
        marginTop: 0
    },
    select: {
        width: 100,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    deleteButton: {
        marginBottom: theme.spacing(2)
    },
    red: {
        color: '#FF5630'
    },
    green: {
        color: '#36B37E'
    }
}));

const Financials = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    const financialsState = {
        isLoaded: false,
        totalAmount: 0,
        financialsAtRisk: false,
        forecasts: [],
        error: null
    };
    const [state, setState] = useState(financialsState);

    useEffect(() => fetchFinancials(), []);
    useEffect(() => calculateAmount(), [state.forecasts]);

    const fetchFinancials = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch).then(() => {
                    state.financialsAtRisk = projectState.projectFinancialsAtRisk;
                }
            )
        } else {
            state.financialsAtRisk = projectState.projectFinancialsAtRisk;
        }
        fetchForecast(params.id);
    };

    const fetchForecast = (id) => {
        const url = `${backendURL}projects/${id}/forecasts`;
        fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    const forecasts = result.map(forecast => (
                        {
                            realisation_date: new Date(forecast.realisation_date),
                            amount: forecast.amount,
                            percentage: forecast.percentage,
                            currency: forecast.currency
                        }
                    ));
                    setState(state => ({...state, isLoaded: true, forecasts: forecasts}));
                },
                (error) => {
                    setState(state => ({...state, isLoaded: true, ...error}));
                }
            );
    };

    const prepareBody = () => {
        const forecasts = [...state.forecasts.filter(f => f.realisation_date !== '' && (f.amount !== '' || f.percentage !== ''))];
        let body = {forecasts: [], financials_at_risk: state.financialsAtRisk}
        for (let forecast of forecasts) {
            let obj = {}
            obj['realisation_date'] = forecast['realisation_date'].toISOString().split('T')[0]
            if (forecast['percentage']) {
                obj['percentage'] = forecast['percentage']
            }
            if (forecast['amount']) {
                obj['amount'] = forecast['amount']
            }
            body.forecasts.push(obj)
        }
        return JSON.stringify(body)
    };

    const calculateAmount = () => {
        let totalAmount = 0
        for (let forecast of state.forecasts) {
            if (forecast['percentage']) {
                totalAmount += projectState.projectAmount * forecast['percentage'] / 100
            }
            if (forecast['amount']) {
                totalAmount += parseFloat(forecast['amount'])
            }
        }
        setState(state => ({...state, totalAmount: totalAmount}));
    };

    const handleSubmit = (event) => {
        let has_errors = false;
        event.preventDefault();
        fetch(`${backendURL}projects/${params.id}/forecasts/bulk_load/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + UserState.token
            },
            body: prepareBody()
        })
            .then((response) => {
                has_errors = !response.ok;
                return response.json()
            })
            .then((responseData) => {
                const error_messages = has_errors ? responseData : {};
                setState(state => ({...state, error_messages: error_messages}));
                return responseData;
            })
            .then((responseData) => {
                if (!has_errors) {
                    projectDispatch({type: 'set', payload: {projectFinancialsAtRisk: state.financialsAtRisk}});
                    toast.success('Success!', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else if (responseData.detail) {
                    toast.error(responseData.detail, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

            });
    };

    const onChangeNumberField = (field, name, index, value) => {
        let values = [...state[field]];
        values[index][name] = value;
        if (name === 'percentage' && value !== '') {
            values[index]['amount'] = ''
        }
        if (name === 'amount' && value !== '') {
            values[index]['percentage'] = ''
        }
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const onChangeDatePickerField = (field, name, index, value) => {
        let values = [...state[field]];
        values[index][name] = value;
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const appendRow = (e, field) => {
        e.preventDefault();
        const values = [...state[field], {
            realisation_date: new Date(),
            amount: '',
            percentage: '',
            currency: projectState.projectCurrency
        }];
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const deleteRow = (e, field) => {
        e.preventDefault();
        const values = [...state[field].slice(0, -1)];
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const classes = useStyles();

    if (state.error) {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar}/>

                <Typography variant="h5">
                    Error: {state.error.message}
                </Typography>
            </main>
        );
    } else if (!state.isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="h5" component="h1">
                            Financials
                        </Typography>
                    </Paper>

                    <br/>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="h6" component="h2">
                            Forecast
                        </Typography>
                        Project amount is {projectState.projectAmount.toLocaleString('en-GB', {
                        style: 'currency',
                        currency: projectState.projectCurrency
                    })}
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="financialsAtRisk"
                                    checked={state.financialsAtRisk}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Project at risk"
                        />
                        <br/>
                        {state.forecasts != null && state.forecasts.map((forecast, index) => (
                            <FormGroup key={`forecast_${index}`} row>
                                <DatePicker
                                    key={`realisation_date_${index}`}
                                    variant="inline"
                                    views={["year", "month"]}
                                    label="Date"
                                    autoOk={true}
                                    minDate={new Date("2018-01-01")}
                                    maxDate={new Date("2030-01-01")}
                                    value={forecast.realisation_date}
                                    onChange={(date) => onChangeDatePickerField('forecasts', 'realisation_date', index, date)}
                                />
                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    key={`amount_${index}`}
                                    name="amount"
                                    label="Amount"
                                    prefix={`${projectState.projectCurrency} `}
                                    value={forecast.amount || ''}
                                    className={classes.inputNumber}
                                    disabled={UserState.role === 'user'}
                                    isAllowed={values => {
                                        const {formattedValue, floatValue} = values
                                        if (floatValue == null) {
                                            return formattedValue === ''
                                        } else {
                                            return (floatValue <= projectState.projectAmount && floatValue >= 0)
                                        }
                                    }}
                                    onValueChange={(values) => onChangeNumberField('forecasts', 'amount', index, values.floatValue)}
                                />
                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    key={`percentage_${index}`}
                                    name="percentage"
                                    label="Percentage"
                                    suffix=' %'
                                    value={forecast.percentage || ''}
                                    className={classes.inputNumber}
                                    disabled={UserState.role === 'user'}
                                    isAllowed={values => {
                                        const {formattedValue, floatValue} = values
                                        if (floatValue == null) {
                                            return formattedValue === ''
                                        } else {
                                            return (floatValue <= 100 && floatValue >= 0)
                                        }
                                    }}
                                    onValueChange={(values) => onChangeNumberField('forecasts', 'percentage', index, values.floatValue)}
                                />
                                {UserState.role !== 'user' && index + 1 === state.forecasts.length &&
                                <IconButton aria-label="delete" className={classes.deleteButton}
                                            onClick={(e) => deleteRow(e, 'forecasts')}>
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>}
                            </FormGroup>
                        ))}

                        {state.totalAmount > 0 &&
                        <p className={state.totalAmount <= projectState.projectAmount ? classes.green : classes.red}>
                            Amount forecasted: {state.totalAmount.toLocaleString('en-GB', {
                            style: 'currency',
                            currency: projectState.projectCurrency
                        })}
                        </p>}
                        <br/>

                        {UserState.role !== 'user' && state.totalAmount < projectState.projectAmount &&
                        <Button variant="outlined" size="small" color="primary"
                                onClick={(e) => appendRow(e, 'forecasts')}>
                            Add Forecast
                        </Button>}
                        <br/>
                    </Paper>

                    <br/>
                    {UserState.role !== 'user' &&
                    state.totalAmount <= projectState.projectAmount &&
                    <Paper className={classes.paper} elevation={1}>
                        <Button
                            type="submit"
                            value="Submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}>
                            Submit
                        </Button>
                    </Paper>}
                </form>
            </main>
        )
    }
}

export default Financials;
