import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";
import UserState from "../../states/UserState";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    textField: {
        width: 400,
    },
    link: {
        marginTop: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

const CloseOut = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 8,
        stage_name: 'closeout',
        closeout_date: '',
        meeting_done: false,
        documentation_handed_over: false,
        feedback_form_sent_to_customer: false,
        feedback_form_exempt: false,
        retrospective_done: false,
        systems_archived: false,
        final_invoice_sent: false,
        customer_feedback: [],
        retrospective_document_id: '',
        retrospective_document_url: ''
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const handleSubmit = (event) => {
        shared.handleSubmit(event, state).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const onChangeFeedbackForm = (e) => {
        const data = shared.onChange(e);
        if (e.target.checked) {
            const field = e.target.name === "feedback_form_sent_to_customer" ? "feedback_form_exempt" : "feedback_form_sent_to_customer"
            data[field] = false
        }
        setState(state => ({...state, ...data}));
    };

    const formatCustomerFeedback = () => {
        const questions = state.customer_feedback[0];
        console.log(state.customer_feedback)

        let results = [];
        for (let index in questions) {
            const result = {
                question: questions[index],
                answers: state.customer_feedback.slice(1).map(function (el) { return el[index]; })
            }
            results.push(result)
        }
        return results

    }
    const classes = useStyles();
    const {
        error_messages,
        closeout_date,
        close_out_meeting_done,
        documentation_handed_over,
        feedback_form_sent_to_customer,
        feedback_form_exempt,
        retrospective_done,
        systems_archived,
        final_invoice_sent,
        is_loaded,
        customer_feedback,
        retrospective_document_id,
        retrospective_document_url
    } = state;

    if (!is_loaded) {
        return shared.loader(classes)
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    { shared.titleBar(state, classes, 'Close-Out') }
                    <br/>

                    <Paper className={classes.paper} elevation={1}>
                        <TextField
                            type="date"
                            id="closeout_date"
                            name="closeout_date"
                            label="Close-Out Date"
                            helperText={error_messages['closeout_date'] !== undefined ? error_messages['closeout_date'] : ""}
                            error={error_messages['closeout_date'] !== undefined}
                            onChange={onChange}
                            value={closeout_date}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"close_out_meeting_done"}
                                    checked={close_out_meeting_done}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Close-Out Meeting Done"
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"documentation_handed_over"}
                                    checked={documentation_handed_over}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Documentation handed over to customer"
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"feedback_form_sent_to_customer"}
                                    checked={feedback_form_sent_to_customer}
                                    onChange={onChangeFeedbackForm}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Feedback form sent to customer"
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"feedback_form_exempt"}
                                    checked={feedback_form_exempt}
                                    onChange={onChangeFeedbackForm}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Feedback form exempt"
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"systems_archived"}
                                    checked={systems_archived}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="All systems archived (GCP, Git, Trello, etc.)"
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"final_invoice_sent"}
                                    checked={final_invoice_sent}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Invoicing complete"
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"retrospective_done"}
                                    checked={retrospective_done}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Retrospective held"
                        />
                        <br/>
                        <TextField
                            id="retrospective_document_id"
                            name={"retrospective_document_id"}
                            label="Retrospective document"
                            disabled={UserState.role === 'user'}
                            helperText={error_messages['retrospective_document_id'] !== undefined ? state.error_messages['retrospective_document_id'] : ""}
                            error={error_messages['retrospective_document_id'] !== undefined}
                            onChange={onChange}
                            className={classes.textField}
                            value={retrospective_document_id}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {retrospective_document_url &&
                        <div style={{display: "inline-flex", marginLeft: "15px"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                className={classes.link}
                                href={retrospective_document_url}
                                target="_blank">
                                Open
                            </Button>
                        </div>
                        }
                        {customer_feedback.length > 0 && <h3>Customer feedback</h3>}
                        {customer_feedback.length > 0 &&
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableBody>
                                {formatCustomerFeedback().map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell component="td" scope="row" key={`question_${rowIndex}`}
                                                   style={{verticalAlign:"top", width: '20%'}}>
                                            {row['question']}
                                        </TableCell>
                                        {row['answers'].map((value, index) => (
                                            <TableCell component="td" scope="row" key={`question_${rowIndex}_${index}`}
                                                       style={{verticalAlign:"top",width: '20%'}}>
                                                {value}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>}
                    </Paper>

                    <br/>
                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
};

export default CloseOut;
