import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import {MenuItem} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    select: {
        marginLeft: 10,
        width: 350
    },
    toolbar: theme.mixins.toolbar,
}));

const Qualification = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 1,
        stage_name: 'qualification',
        meeting_setup: false,
        bant_budget: 'Exact budget given',
        bant_budget_description: '',
        bant_authority: 'n/a',
        bant_authority_description: '',
        bant_need: 'n/a',
        bant_need_description: '',
        bant_timeline: 'n/a',
        bant_timeline_description: '',
        decision: 'Passed',
        decision_communicated_to_partner: false,
        decision_communicated_to_customer: false
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const handleSubmit = (event) => {
        shared.handleSubmit(event, state).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const classes = useStyles();
    const {
        is_loaded,
        error_messages,
        meeting_setup,
        bant_budget,
        bant_budget_description,
        bant_authority,
        bant_authority_description,
        bant_need,
        bant_need_description,
        bant_timeline,
        bant_timeline_description,
        decision,
        decision_communicated_to_partner,
        decision_communicated_to_customer
    } = state;
    const availableBantAuthorities = [
        'n/a',
        'Have clearly identified process and people',
        'Have vague understanding',
        'Don\'t know',
        'Confirmed NO authority decided',
    ];
    const availableBantBudgets = [
        'Exact budget given',
        'Budget Exists',
        'Not specified',
        'Refuse to disclose',
        'Confirmed NO budget'
    ];
    const availableBantNeeds = [
        'n/a',
        'Compelling need',
        'Clear need',
        'No need'
    ];
    const availableBantTimelines = [
        'n/a',
        'Specific decision dates given',
        'Vague decision dates given',
        'Refused to give date',
        'Confirmed NO decision date'
    ];
    const availableDecisions = [
        'Passed',
        'Not Passed'
    ];

    if (!is_loaded) {
        return shared.loader(classes)
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    { shared.titleBar(state, classes, 'Qualification') }
                    <br/>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"meeting_setup"}
                                    checked={meeting_setup}
                                    onChange={onChange}
                                    color={"primary"}
                                />
                            }
                            label="The qualification meeting has been setup"
                        />

                        <br/>
                        <InputLabel
                            className={classes.inputLabel}
                            style={{fontSize: "12px"}}
                            label={
                                error_messages['bant_budget'] !== undefined ?
                                    "bant_budget: " + error_messages['bant_budget'] :
                                    "bant_budget"
                            }
                            error={error_messages['bant_budget'] !== undefined}
                        >
                            Bant Budget
                        </InputLabel>
                        <Select
                            id={"bant_budget"}
                            name={"bant_budget"}
                            label={"bant_budget"}
                            value={bant_budget}
                            onChange={onChange}
                            className={classes.select}
                            fullWidth
                        >
                            {availableBantBudgets.map(item => (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            id="bant_budget_description"
                            name={"bant_budget_description"}
                            label={
                                error_messages['bant_budget_description'] !== undefined ?
                                    "bant_budget_description: " + error_messages['bant_budget_description'] :
                                    "bant_budget_description"
                            }
                            error={error_messages['bant_budget_description'] !== undefined}
                            fullWidth
                            value={bant_budget_description}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Paper>
                    <br/>

                    <Paper className={classes.paper}>
                        <InputLabel
                            className={classes.inputLabel}
                            style={{fontSize: "12px"}}
                            label={
                                error_messages['bant_authority'] !== undefined ?
                                    "bant_authority: " + error_messages['bant_authority'] :
                                    "bant_authority"
                            }
                            error={error_messages['bant_authority'] !== undefined}
                        >
                            Bant Authority
                        </InputLabel>
                        <Select
                            id={"bant_authority"}
                            name={"bant_authority"}
                            label={"bant_authority"}
                            value={bant_authority}
                            onChange={onChange}
                            className={classes.select}
                            fullWidth
                        >
                            {availableBantAuthorities.map(item => (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            id="bant_authority_description"
                            name={"bant_authority_description"}
                            label={
                                error_messages['bant_authority_description'] !== undefined ?
                                    "bant_authority_description: " + error_messages['bant_authority_description'] :
                                    "bant_authority_description"
                            }
                            error={error_messages['bant_authority_description'] !== undefined}
                            fullWidth
                            value={bant_authority_description}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Paper>
                    <br/>

                    <Paper className={classes.paper}>
                        <InputLabel
                            className={classes.inputLabel}
                            style={{fontSize: "12px"}}
                            label={
                                error_messages['bant_need'] !== undefined ?
                                    "bant_need: " + error_messages['bant_need'] :
                                    "bant_need"
                            }
                            error={error_messages['bant_need'] !== undefined}
                        >
                            Bant Need
                        </InputLabel>
                        <Select
                            id={"bant_need"}
                            name={"bant_need"}
                            label={"bant_need"}
                            value={bant_need}
                            onChange={onChange}
                            className={classes.select}
                            fullWidth
                        >
                            {availableBantNeeds.map(item => (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>

                        <TextField
                            id="bant_need_description"
                            name={"bant_need_description"}
                            label={
                                error_messages['bant_need_description'] !== undefined ?
                                    "bant_need_description: " + error_messages['bant_need_description'] :
                                    "bant_need_description"
                            }
                            error={error_messages['bant_need_description'] !== undefined}
                            fullWidth
                            value={bant_need_description}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Paper>
                    <br/>

                    <Paper className={classes.paper}>
                        <InputLabel
                            className={classes.inputLabel}
                            style={{fontSize: "12px"}}
                            label={
                                error_messages['bant_timeline'] !== undefined ?
                                    "bant_timeline: " + error_messages['bant_timeline'] :
                                    "bant_timeline"
                            }
                            error={error_messages['bant_timeline'] !== undefined}
                        >
                            Bant Timeline
                        </InputLabel>
                        <Select
                            id={"bant_timeline"}
                            name={"bant_timeline"}
                            label={"bant_timeline"}
                            value={bant_timeline}
                            onChange={onChange}
                            className={classes.select}
                            fullWidth
                        >
                            {availableBantTimelines.map(item => (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>

                        <TextField
                            id="bant_timeline_description"
                            name={"bant_timeline_description"}
                            label={
                                error_messages['bant_timeline_description'] !== undefined ?
                                    "bant_timeline_description: " + error_messages['bant_timeline_description'] :
                                    "bant_timeline_description"
                            }
                            error={error_messages['bant_timeline_description'] !== undefined}
                            fullWidth
                            value={bant_timeline_description}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Paper>
                    <br/>

                    <Paper className={classes.paper}>
                        <InputLabel
                            className={classes.inputLabel}
                            style={{fontSize: "12px"}}
                            label={
                                error_messages['decision'] !== undefined ?
                                    "decision: " + error_messages['decision'] :
                                    "decision"
                            }
                            error={error_messages['decision'] !== undefined}
                        >
                            Decision
                        </InputLabel>
                        <Select
                            id={"decision"}
                            name={"decision"}
                            label={"decision"}
                            value={decision}
                            onChange={onChange}
                            className={classes.select}
                        >
                            {availableDecisions.map(item => (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"decision_communicated_to_partner"}
                                    checked={decision_communicated_to_partner}
                                    onChange={onChange}
                                    color={"primary"}
                                />
                            }
                            label="Qualification decision has been communicated to partner"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"decision_communicated_to_customer"}
                                    checked={decision_communicated_to_customer}
                                    onChange={onChange}
                                    color={"primary"}
                                />
                            }
                            label="Qualification decision has been communicated to client"
                        />
                    </Paper>
                    <br/>

                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
}

export default Qualification;
