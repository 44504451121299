import React, {useContext, useState} from 'react';
import slugify from "slugify";
import {Link} from "react-router-dom";

import List from '@material-ui/core/List';
import InfoIcon from '@material-ui/icons/Info';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import MaterialDrawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import {STAGES} from "../constants";
import ProjectContext from "../store";
import {green, grey, red, orange, blue} from "@material-ui/core/colors";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => createStyles({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,

    currentStage: {
        fontWeight: 500,
        color: blue[600]
    }
}));

const Drawer = () => {
    const match = window.location.pathname.match(/\/projects\/(\d+)/);
    const projectId = match ? match[1] : null;
    const drawerState = {
        selectedItem: ''
    };
    const [state, setState] = useState(drawerState);

    const [projectState,] = useContext(ProjectContext);

    const changeSelectedItem = (event, name) => {
        if (name === 'Delivered') {
            event.preventDefault();
        } else {
            setState(state => ({...state, selectedItem: name}));
        }
    };

    const selectColor = (stage) => {
        if (projectState.projectStage === 'Delivered' && stage.label === 'Delivered') {
            return green[500];
        }
        // Show Lost and Parked grey if not the current stage
        const showStageGrey = projectState.projectStage !== 'Lost' && stage.label === 'Lost' || projectState.projectStage !== 'Parked' && stage.label === 'Parked';
        // Show all grey except Lost if current stage is Lost
        const lostGreyStage = projectState.projectStage === 'Lost' && stage.label !== 'Lost';
        // Show all grey except Parked if current stage is Parked
        const parkedGreyStage = projectState.projectStage === 'Parked' && stage.label !== 'Parked';
        if (projectState.projectStageId < stage.value || showStageGrey || lostGreyStage || parkedGreyStage) {
            return grey[500];
        }
        const stageName = slugify(stage.label, {lower: true}).replace('-', '');
        if (projectState.projectStageStatus) {
            switch (projectState.projectStageStatus[stageName]) {
            case 'completed':
                return green[500];
            case 'started':
                return orange[500];
            default:
                return red[500];
            }
        }
    };
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssBaseline/>

            <MaterialDrawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.toolbar} style={{minHeight: "40px"}}/>

                <List>
                    <ListItem button
                              selected={("Info" === state.selectedItem)}
                              key={"Info"}
                              onClick={() => changeSelectedItem("Info")}
                              component={Link}
                              to={"/projects/" + projectId}>
                        <ListItemIcon>
                            <InfoIcon/>
                        </ListItemIcon>

                        <ListItemText>
                            Info
                        </ListItemText>
                    </ListItem>

                    <Divider/>
                    {STAGES.map((stage) => (
                        <ListItem
                            selected={(stage.label === state.selectedItem)}
                            button
                            key={stage.label}
                            component={Link}
                            onClick={(e) => changeSelectedItem(e, stage.label)}
                            to={"/projects/" + projectId + "/" + slugify(stage.label, {lower: true})}>
                            <ListItemIcon>
                                <FiberManualRecord style={{color: selectColor(stage)}}/>
                            </ListItemIcon>
                            <ListItemText
                                classes={{primary: stage.label === projectState.projectStage ? classes.currentStage : ''}}
                                primary={stage.label}
                            />
                        </ListItem>
                    ))}

                    <Divider/>
                    <ListItem button
                              selected={("Systems" === state.selectedItem)}
                              key={"Systems"}
                              onClick={() => changeSelectedItem("Systems")}
                              component={Link}
                              to={"/projects/" + projectId + "/systems"}>
                        <ListItemIcon>
                            <AttachFileIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            Systems
                        </ListItemText>
                    </ListItem>
                    <ListItem button
                              selected={("Financials" === state.selectedItem)}
                              key={"Financials"}
                              onClick={() => changeSelectedItem("Financials")}
                              component={Link}
                              to={"/projects/" + projectId + "/financials"}>
                        <ListItemIcon>
                            <AttachMoneyIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            Financials
                        </ListItemText>
                    </ListItem>
                </List>


            </MaterialDrawer>
        </div>
    )
};

export default Drawer;
