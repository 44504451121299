/**
 This CodeSandbox has been automatically generated using
 `codesandboxer`. If you're curious how that happened, you can
 check out our docs here: https://github.com/noviny/codesandboxer

 If you experience any struggles with this sandbox, please raise an issue
 on github. :)
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
