const URLs = {
    'local': 'http://genesis-local.com:8000/',
    'staging': 'https://backend-staging.genesis.datatonic.com/',
    'production': 'https://backend.genesis.datatonic.com/',
}

export function currentEnv()  {
    if (window.location.hostname === "genesis.datatonic.com") {
        return 'production'
    } else if (window.location.hostname === "staging.genesis.datatonic.com") {
        return 'staging'
    }
    return 'local'

}

export function getBackendURL() {
    const env = currentEnv()
    return URLs[env]
}
