import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {getBackendURL} from "../context/host"
import UserState from "../states/UserState";
import {toast} from "react-toastify";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Select from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import ProjectContext from "../store";
import {KeyboardDatePicker} from "@material-ui/pickers";

const backendURL = getBackendURL();
const availableClassification = ["Customer", "Prospect", "Partner", "Competitor", "Other"];

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    bigPaper: {
        minHeight: 750
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,

    inputLabel: {
        fontSize: 12
    },
    select: {
        marginTop: 5,
        marginBottom: 5,
        width: 400,
    },
    input: {
        width: 400,
    },
    inputSmall: {
        width: 100,
    },
    inputDate: {
        width: 150,
    },
    inputRight: {
        marginLeft: theme.spacing(1)
    },
    link: {
        marginTop: theme.spacing(3),
    }
}));

const Account = props => {
    const {match: {params}} = props;
    const [, projectDispatch] = useContext(ProjectContext);

    const accountState = {
        id: '',
        name: '',
        account_code: '',
        classification: '',
        pipedrive_id: '',
        pipedrive_url: '',
        xero_id: '',
        xero_url: '',
        harvest_id: '',
        harvest_url: '',
        googledrive_businessdevelopment_folder_id: '',
        googledrive_businessdevelopment_folder_url: '',
        googledrive_delivery_folder_id: '',
        googledrive_delivery_folder_url: '',
        googledrive_legal_folder_id: '',
        googledrive_legal_folder_url: '',
        legal_is_under_nda: false,
        legal_nda_start_date: '',
        legal_nda_end_date: '',
        legal_nda_googledrive_document_id: '',
        legal_nda_googledrive_document_url: '',
        legal_msa_defined: false,
        legal_msa_googledrive_document_id: '',
        legal_msa_googledrive_document_url: '',
        invoice_process_documented: false,
        account_type: '',
        google_account_manager: '',
        google_vertical: '',
        google_account_type: '',
        looker_customer: false,
        potential_looker_customer: false,
        hype_customer: false,
        potential_hype_customer: false,
        invoice_process_description: '',
        error: null,
        isLoaded: false,
        interested: false,
        error_messages: {}
    };
    const [state, setState] = useState(accountState);

    useEffect(() => fetchAcount(), []);

    const fetchAcount = () => {
        fetch(`${backendURL}accounts/${params.id}/`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    let responseState = {
                        isLoaded: true
                    };
                    for (let field in accountState) {
                        if (result[field] && result[field] !== null) {
                            responseState[field] = result[field]
                        }
                    }
                    setState(state => ({...state, ...responseState}));
                    const payload = {
                        projectAccount: result.name,
                        projectAccountId: result.id,
                    };
                    projectDispatch({type: 'set', payload: payload});
                },
                (error) => {
                    setState(state => ({...state, ...error, isLoaded: true}));
                }
            )
            .catch(error => {
                setState(state => ({...state, ...error, isLoaded: true}));
            });
    };

    const onChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const data = {[e.target.name]: value};
        setState(state => ({...state, ...data}));
    };

    const onChangeDatePicker = (field, value) => {
        const data = {[field]: value};
        setState(state => ({...state, ...data}));
    };

    const onChangeSelect = name => item => {
        const value = item ? item.value : null;
        setState(state => ({...state, [name]: value}));
    };

    const prepareBody = () => {
        let body = {...state};
        for (let field in body) {
            if (body[field] === '') {
                body[field] = null;
            } else {
                if (body[field] !== null && typeof(body[field]) !== 'string' &&
                    (field === 'legal_nda_start_date' || field === 'legal_nda_end_date')) {
                    body[field] = body[field].toISOString().split('T')[0];
                }
            }
        }
        return JSON.stringify(body)
    };

    const handleSubmit = (event) => {
        let has_errors = false;
        event.preventDefault();
        fetch(`${backendURL}accounts/${state.id}/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + UserState.token
            },
            body: prepareBody()
        })
            .then((response) => {
                has_errors = !response.ok;
                return response.json()
            })
            .then((responseData) => {
                const error_messages = has_errors ? responseData : {};
                setState(state => ({...state, error_messages: error_messages}));
                return responseData;
            })
            .then((responseData) => {
                if (!has_errors) {
                    toast.success('Success!', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else if (responseData.detail) {
                    toast.error(responseData.detail, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

            });
    };

    const classes = useStyles();
    const fields = ['pipedrive', 'harvest', 'xero',
        'googledrive_businessdevelopment_folder',
        'googledrive_delivery_folder',
        'googledrive_legal_folder',];
    if (state.error) {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar}/>

                <Typography variant="h5">
                    Error: {state.error.message}
                </Typography>
            </main>
        );
    } else if (!state.isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Paper className={`${classes.paper} ${classes.bigPaper}`} elevation={1}>
                                <TextField
                                    id="name"
                                    name={"name"}
                                    label="name"
                                    disabled={true}
                                    className={classes.input}
                                    value={state.name}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="project_code"
                                    name="account_code"
                                    label="account_code"
                                    className={`${classes.inputRight} ${classes.inputSmall}`}
                                    value={state.account_code}
                                    helperText={state.error_messages['account_code'] !== undefined ? state.error_messages['account_code'] : ""}
                                    error={state.error_messages['account_code'] !== undefined}
                                    onChange={onChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="looker_customer"
                                            checked={state.looker_customer}
                                            disabled={true}
                                            inputProps={{'aria-label': 'primary checkbox',}}
                                            color={"primary"}
                                        />
                                    }
                                    label="Looker customer"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="potential_looker_customer"
                                            checked={state.potential_looker_customer}
                                            disabled={true}
                                            inputProps={{'aria-label': 'primary checkbox',}}
                                            color={"primary"}
                                        />
                                    }
                                    label="Potential Looker customer"
                                />
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="hype_customer"
                                            checked={state.hype_customer}
                                            disabled={true}
                                            inputProps={{'aria-label': 'primary checkbox',}}
                                            color={"primary"}
                                        />
                                    }
                                    label="knownx.ai customer"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="potential_hype_customer"
                                            checked={state.potential_hype_customer}
                                            disabled={true}
                                            inputProps={{'aria-label': 'primary checkbox',}}
                                            color={"primary"}
                                        />
                                    }
                                    label="Potential knownx.ai customer"
                                />
                                <br/>
                                <TextField
                                    id="account_type"
                                    name={"account_type"}
                                    label="Account type"
                                    disabled={true}
                                    className={classes.input}
                                    value={state.account_type}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                <TextField
                                    id="google_account_type"
                                    name={"google_account_type"}
                                    label="Google account type"
                                    disabled={true}
                                    className={classes.input}
                                    value={state.google_account_type}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                <TextField
                                    id="google_account_manager"
                                    name={"google_account_manager"}
                                    label="Google account manager"
                                    disabled={true}
                                    className={classes.input}
                                    value={state.google_account_manager}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                <TextField
                                    id="google_vertical"
                                    name={"google_vertical"}
                                    label="Google vertical"
                                    disabled={true}
                                    className={classes.input}
                                    value={state.google_vertical}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/> <br/>
                                <InputLabel
                                    className={classes.inputLabel}
                                    label={
                                        state.error_messages['classification'] !== undefined ?
                                            "classification: " + state.error_messages['classification'] :
                                            "classification"
                                    }
                                    error={state.error_messages['classification'] !== undefined}
                                >
                                    classification
                                </InputLabel>
                                <Select
                                    id="classification"
                                    name={"classification"}
                                    label="Classification"
                                    value={{value: state.classification, label: state.classification}}
                                    isClearable={true}
                                    isDisabled={UserState.role === 'user'}
                                    onChange={onChangeSelect('classification')}
                                    className={classes.select}
                                    options={availableClassification.map(item => (
                                        {value: item, label: item}
                                    ))}
                                />
                                <br/>
                                <TextField
                                    id="invoice_process_description"
                                    name="invoice_process_description"
                                    label={
                                        state.error_messages['invoice_process_description'] !== undefined ?
                                            "invoice_process_description: " + state.error_messages['invoice_process_description'] :
                                            "invoice_process_description"
                                    }
                                    error={state.error_messages['invoice_process_description'] !== undefined}
                                    fullWidth
                                    value={state.invoice_process_description}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    margin="normal"
                                    multiline={true}
                                    rows={2}
                                    rowsMax={6}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs>
                            <Paper className={`${classes.paper} ${classes.bigPaper}`} elevation={1}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="legal_is_under_nda"
                                            onChange={onChange}
                                            checked={state.legal_is_under_nda}
                                            disabled={UserState.role === 'user'}
                                            inputProps={{'aria-label': 'primary checkbox',}}
                                            color={"primary"}
                                        />
                                    }
                                    label="Account is under NDA"
                                />
                                <br/>
                                <KeyboardDatePicker
                                    variant="inline"
                                    label="NDA start date"
                                    autoOk={true}
                                    minDate={new Date("2018-01-01")}
                                    maxDate={new Date("2030-01-01")}
                                    format="dd/MM/yyyy"
                                    emptyLabel  ="dd/mm/yyyy"
                                    value={state.legal_nda_start_date === ''? null : state.legal_nda_start_date}
                                    disabled={UserState.role === 'user'}
                                    onChange={date => onChangeDatePicker('legal_nda_start_date', date)}
                                    className={classes.inputDate}
                                />
                                <KeyboardDatePicker
                                    variant="inline"
                                    label="NDA end date"
                                    autoOk={true}
                                    minDate={new Date("2018-01-01")}
                                    maxDate={new Date("2030-01-01")}
                                    format="dd/MM/yyyy"
                                    emptyLabel="dd/mm/yyyy"
                                    value={state.legal_nda_end_date === ''? null : state.legal_nda_end_date}
                                    disabled={UserState.role === 'user'}
                                    onChange={date => onChangeDatePicker('legal_nda_end_date', date)}
                                    className={`${classes.inputDate} ${classes.inputRight}`}
                                />
                                <br/>
                                <TextField
                                    id="legal_nda_googledrive_document_id"
                                    name={"legal_nda_googledrive_document_id"}
                                    label="Google Drive NDA Document ID"
                                    disabled={UserState.role === 'user'}
                                    helperText={state.error_messages['legal_nda_googledrive_document_id'] !== undefined ? state.error_messages['state.legal_nda_googledrive_document_id'] : ""}
                                    error={state.error_messages['legal_nda_googledrive_document_id'] !== undefined}
                                    onChange={onChange}
                                    className={classes.input}
                                    value={state.legal_nda_googledrive_document_id}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {state.legal_nda_googledrive_document_url &&
                                <div style={{display: "inline-flex", marginLeft: "15px"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        className={classes.link}
                                        href={state.legal_nda_googledrive_document_url}
                                        target="_blank">
                                        Open
                                    </Button>
                                </div>
                                }
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="legal_msa_defined"
                                            onChange={onChange}
                                            checked={state.legal_msa_defined}
                                            disabled={UserState.role === 'user'}
                                            inputProps={{'aria-label': 'primary checkbox',}}
                                            color={"primary"}
                                        />
                                    }
                                    label="Account has MSA associated"
                                />
                                <br/>
                                <TextField
                                    id="legal_msa_googledrive_document_id"
                                    name={"legal_msa_googledrive_document_id"}
                                    label="legal_msa_googledrive_document_id"
                                    disabled={UserState.role === 'user'}
                                    helperText={state.error_messages['legal_msa_googledrive_document_id'] !== undefined ? state.error_messages['state.legal_msa_googledrive_document_id'] : ""}
                                    error={state.error_messages['legal_msa_googledrive_document_id'] !== undefined}
                                    onChange={onChange}
                                    className={classes.input}
                                    value={state.legal_msa_googledrive_document_id}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {state.legal_msa_googledrive_document_url &&
                                <div style={{display: "inline-flex", marginLeft: "15px"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        className={classes.link}
                                        href={state.legal_msa_googledrive_document_url}
                                        target="_blank">
                                        Open
                                    </Button>
                                </div>
                                }
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="invoice_process_documented"
                                            checked={state.invoice_process_documented}
                                            onChange={onChange}
                                            disabled={UserState.role === 'user'}
                                            inputProps={{'aria-label': 'primary checkbox',}}
                                            color={"primary"}
                                        />
                                    }
                                    label="The invoice process for this customer has been documented"
                                />

                                {fields.map((field) =>
                                    <div key={field}>
                                        <TextField
                                            id={field + '_id'}
                                            name={field + '_id'}
                                            label={field.replace(/_/g, ' ')}
                                            disabled={field === 'pipedrive'}
                                            helperText={state.error_messages[field + '_id'] !== undefined ? state.error_messages[field + '_id'] : ""}
                                            error={state.error_messages[field + '_id'] !== undefined}
                                            onChange={onChange}
                                            value={state[field + '_id'] ? state[field + '_id'] : ''}
                                            margin="normal"
                                            className={classes.input}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {state[field + '_url'] &&
                                        <div style={{display: "inline-flex", marginLeft: "15px"}}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component={Link}
                                                className={classes.link}
                                                href={state[field + '_url']}
                                                target="_blank">
                                                Open
                                            </Button>
                                        </div>
                                        }
                                        <br/>
                                    </div>)}
                            </Paper>
                        </Grid>
                    </Grid>
                    <br/>
                    {UserState.role !== 'user' && <Paper className={classes.paper} elevation={1}>
                        <Button
                            type="submit"
                            value="Submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}>
                            Submit
                        </Button>
                    </Paper>}
                </form>
            </main>
        )
    }
};

export default Account;
