import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import TextField from "@material-ui/core/TextField";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

const Lost = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 9,
        stage_name: 'lost',
        lost_date: '',
        loss_analysis: '',
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const handleSubmit = (event) => {
        shared.handleSubmit(event, state).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const classes = useStyles();
    const {
        is_loaded,
        error_messages,
        lost_date,
        loss_analysis
    } = state;

    if (!is_loaded) {
        return shared.loader(classes)
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    { shared.titleBar(state, classes, 'Lost') }
                    <br/>

                    <Paper className={classes.paper} elevation={1}>

                        <TextField
                            type="date"
                            id="lost_date"
                            name={"lost_date"}
                            label="Lost date"
                            helperText={error_messages['lost_date'] !== undefined ? error_messages['lost_date'] : ''}
                            error={error_messages['lost_date'] !== undefined}
                            onChange={onChange}
                            value={lost_date}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <br/>
                        <TextField
                            id="loss_analysis"
                            name={"loss_analysis"}
                            label={
                                error_messages['loss_analysis'] !== undefined ?
                                    "Perspective on why we lost the opportunity" + error_messages['loss_analysis'] :
                                    "Perspective on why we lost the opportunity"
                            }
                            error={error_messages['loss_analysis'] !== undefined}
                            fullWidth
                            value={loss_analysis}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Paper>

                    <br/>
                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
};

export default Lost;
