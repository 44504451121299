import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography/index";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import UserState from "../../states/UserState";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const availableRoles = [
    'Head of ML/Data Science',
    'Principal Data Scientist',
    'Senior Data Scientist',
    'Data Scientist',
    'Senior ML Engineer',
    'ML Engineer',
    'ML Researcher',
    'ML Graduate',
    'Head of Analytics',
    'Senior BI Analyst',
    'BI Analyst',
    'Senior BI Consultant',
    'BI Consultant',
    'Analytics Graduate',
    'Head of Engineering',
    'Senior DevOps Engineer',
    'DevOps Engineer',
    'Senior Data Engineer',
    'Data Engineer',
    'Software Engineer',
    'DWH Developer',
    'Engineering Graduate',
    'Head of Strategy',
    'Delivery Lead',
    'Delivery Manager',
    'Engagement Manager'
];

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    inputLabel: {
        fontSize: 12
    },
    roleLabel: {
        width: 220
    },
    select: {
        marginTop: 5,
        marginBottom: 5,
        width: 600,
    },
    textField: {
        width: '400px'
    },
    toolbar: theme.mixins.toolbar,
}));

const Proposal = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 3,
        stage_name: 'proposal',
        description: '',
        roles: [],
        engagement_lead_briefed: false,
        engagement_lead_feedback_received: false,
        resource_planning_project_plan_checked_vs_staffing_plan: false,
        reviewed_with_headofgrowth: false,
        reviewed_with_partner: false,
        partner_funding_recorded: false,
        signature_fields_available: false,
        shared_with_customer: false,
        customer_informed_staff_contractors: false,
        gcp_consumption_estimated: false,
        gcp_consumption: '',
        gcp_calculator_url: '',
        proposal_exempt: false,
        budget_calculator_exempt: false,
        forecast_exempt: false
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const handleSubmit = (event) => {
        shared.handleSubmit(event, state).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const onChangeSelect = name => roles => {
        const newRoles = roles ? roles.map(role => role.value) : [];
        const data = {[name]: newRoles};
        setState(state => ({...state, ...data}));
    };

    const onChangeNumberFormat = (field, values) => {
        const data = {[field]: values.value};
        setState(state => ({...state, ...data}));
    };

    const classes = useStyles();
    const {
        is_loaded,
        description,
        roles,
        engagement_lead_briefed,
        engagement_lead_feedback_received,
        resource_planning_project_plan_checked_vs_staffing_plan,
        reviewed_with_headofgrowth,
        reviewed_with_partner,
        partner_funding_recorded,
        signature_fields_available,
        shared_with_customer,
        customer_informed_staff_contractors,
        gcp_consumption_estimated,
        gcp_consumption,
        gcp_calculator_url,
        proposal_exempt,
        budget_calculator_exempt,
        forecast_exempt,
        error_messages
    } = state;

    if (!is_loaded) {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar}/>

                <Paper className={classes.paper}>
                    <Typography variant="h5">
                        Loading ...
                    </Typography>
                </Paper>
            </main>
        )
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    {shared.titleBar(state, classes, 'Proposal')}
                    <br/>

                    <Paper className={classes.paper} elevation={1}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"proposal_exempt"}
                                    checked={proposal_exempt}
                                    onChange={onChange}
                                    disabled={UserState.role !== 'superuser'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Proposal exempt"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"budget_calculator_exempt"}
                                    checked={budget_calculator_exempt}
                                    onChange={onChange}
                                    disabled={UserState.role !== 'superuser'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Budget calculator exempt"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"forecast_exempt"}
                                    checked={forecast_exempt}
                                    onChange={onChange}
                                    disabled={UserState.role !== 'superuser'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Forecast exempt"
                        />

                        <TextField
                            id="description"
                            name={"description"}
                            label="Project high level description"
                            error={error_messages['description'] !== undefined}
                            fullWidth
                            value={description}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={10}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <br/>
                        <InputLabel className={classes.inputLabel}>
                            Roles included
                        </InputLabel>
                        <Select
                            id="roles"
                            name={"roles"}
                            label="roles"
                            isMulti
                            value={roles.map(role => ({value: role, label: role}))}
                            isDisabled={UserState.role === 'user'}
                            onChange={onChangeSelect('roles')}
                            className={classes.select}
                            options={availableRoles.map(item => (
                                {value: item, label: item}
                            ))}
                        />
                        <br/><br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"engagement_lead_briefed"}
                                    checked={engagement_lead_briefed}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The engagement lead has been briefed on the business objectives"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"engagement_lead_feedback_received"}
                                    checked={engagement_lead_feedback_received}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The engagement lead has provided feedback on the business objectives, project setup, and scope of work"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"resource_planning_project_plan_checked_vs_staffing_plan"}
                                    checked={resource_planning_project_plan_checked_vs_staffing_plan}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The staffing plan in Forecast aligns with the project plan"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"reviewed_with_headofgrowth"}
                                    checked={reviewed_with_headofgrowth}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The proposal has been reviewed and signed off by the Head of Growth, Team Lead and Delivery Manager"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"reviewed_with_partner"}
                                    checked={reviewed_with_partner}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The proposal has been shared with and reviewed by the partner."
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"partner_funding_recorded"}
                                    checked={partner_funding_recorded}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="It is clear how this project will be funded."
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"signature_fields_available"}
                                    checked={signature_fields_available}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The proposal contains signature fields for the customer and Datatonic"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"shared_with_customer"}
                                    checked={shared_with_customer}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The proposal has been shared with the customer"
                        />


                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"customer_informed_staff_contractors"}
                                    checked={customer_informed_staff_contractors}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Customer has been informed of the the intent to staff contractors (should they require us to do so)"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"gcp_consumption_estimated"}
                                    checked={gcp_consumption_estimated}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="The GCP consumption has been estimated"
                        />
                        <br/>

                        <NumberFormat
                            customInput={TextField}
                            thousandSeparator={true}
                            onValueChange={(values) => onChangeNumberFormat('gcp_consumption', values)}
                            helperText={error_messages['gcp_consumption'] !== undefined ? error_messages['gcp_consumption'] : ""}
                            error={error_messages['gcp_consumption'] !== undefined}
                            name="gcp_consumption"
                            label="GCP Consumption"
                            value={gcp_consumption}
                            prefix="$"
                            className={classes.inputNumber}
                            disabled={UserState.role === 'user'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <br/>
                        <TextField
                            id="gcp_calculator_url"
                            name={"gcp_calculator_url"}
                            label="GCP calculator link"
                            disabled={UserState.role === 'user'}
                            helperText={error_messages['gcp_calculator_url'] !== undefined ? error_messages['gcp_calculator_url'] : ""}
                            error={error_messages['gcp_calculator_url'] !== undefined}
                            onChange={onChange}
                            className={classes.textField}
                            value={gcp_calculator_url}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {gcp_calculator_url &&
                        <div style={{display: "inline-flex", marginLeft: "15px", marginTop: "25px"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                className={classes.link}
                                href={gcp_calculator_url}
                                target="_blank">
                                Open
                            </Button>
                        </div>
                        }
                    </Paper>

                    <br/>

                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
};

export default Proposal;
