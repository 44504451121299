import {observable, action, computed} from "mobx";
import Cookie from 'mobx-cookie'

/**
 * UserState
 * @module src/helpers/UserState
 */

class UserState {
    @observable userCookie = new Cookie('user');
    @observable tokenCookie = new Cookie('token');
    @observable roleCookie = new Cookie('role');

    @computed
    get user() {
        return this.userCookie.value
    }

    @computed
    get token() {
        return this.tokenCookie.value
    }

    @computed
    get role() {
        return this.roleCookie.value
    }

    @action
    setUser = value => {
        this.userCookie.set(value, {expires: 1})
    };

    @action
    setToken = value => {
        this.tokenCookie.set(value, {expires: 1})
    };

    @action
    setRole = value => {
        this.roleCookie.set(value, {expires: 1})
    };

    @action
    unsetUser = () => {
        this.userCookie.remove();
        this.tokenCookie.remove();
        this.roleCookie.remove();
    };
}

const singletonUserState = new UserState();

export default singletonUserState;
