import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

const KickOff = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 6,
        stage_name: 'kickoff',
        kickoff_date: '',
        identified_templates: false,
        identified_prior_projects: false,
        communicated_objectives: false,
        communicated_delivery_team: false,
        established_rules_of_engagement: false,
        defined_communication_plan: false,
        communicate_internally: false,
        project_one_slide_description_available: false
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const handleSubmit = (event) => {
        shared.handleSubmit(event, state).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const classes = useStyles();
    const {
        is_loaded,
        identified_templates,
        identified_prior_projects,
        communicated_objectives,
        communicated_delivery_team,
        established_rules_of_engagement,
        defined_communication_plan,
        communicate_internally,
        kickoff_date,
        project_one_slide_description_available,
        error_messages
    } = state;

    if (!is_loaded) {
        return shared.loader(classes)
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    { shared.titleBar(state, classes, 'Kick-Off') }
                    <br/>

                    <Paper className={classes.paper} elevation={1}>

                        <TextField
                            id="kickoff_date"
                            name={"kickoff_date"}
                            type="date"
                            label="Kick-off date"
                            helperText={error_messages['kickoff_date'] !== undefined ? error_messages['kickoff_date'] : ""}
                            error={error_messages['kickoff_date'] !== undefined}
                            value={kickoff_date}
                            onChange={onChange}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"identified_templates"}
                                    checked={identified_templates}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Templates identified"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"identified_prior_projects"}
                                    checked={identified_prior_projects}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Identified prior projects with useful code, approaches, etc."
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"communicated_objectives"}
                                    checked={communicated_objectives}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Business and technical objectives have been re-iterated with the customer"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"communicated_delivery_team"}
                                    checked={communicated_delivery_team}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Delivery Team setup communicated to the client, with a differentiation between employee / contractor"
                        />

                        <br/>
                        <hr/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"established_rules_of_engagement"}
                                    checked={established_rules_of_engagement}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Rules of engagement / ways of working have been clearly communicated to the customer"
                        />
                        <br/>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"defined_communication_plan"}
                                    checked={defined_communication_plan}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Customer and partner communication plan defined"
                        />
                        <br/>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"communicate_internally"}
                                    checked={communicate_internally}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Internal communications set up"
                        />
                        <br/>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"project_one_slide_description_available"}
                                    checked={project_one_slide_description_available}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Internal one-slide project description created"
                        />
                    </Paper>

                    <br/>

                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
};

export default KickOff;
