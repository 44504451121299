import UserState from "../../states/UserState";
import {getBackendURL} from "../../context/host";
import {toast} from "react-toastify";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Button from "@material-ui/core/Button";

const backendURL = getBackendURL();

export const baseState = {
    id: '',
    is_loaded: false,
    role: UserState.role,
    project: '',
    status: 'empty',
    error_messages: {}
};

export const setProjectStageStatus = (projectState, projectDispatch, state) => {
    let stageStatus = projectState.projectStageStatus;
    if (stageStatus) {
        stageStatus[state.stage_name] = state.status;
        projectDispatch({type: 'set', payload: {projectStageStatus: stageStatus}});
    }
};

export const onChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    return {[e.target.name]: value}
};

export const loader = (classes) => {
    return (
        <main className={classes.content}>
            <div className={classes.toolbar}/>
            <Paper className={classes.paper}>
                <Typography variant="h5">
                    Loading ...
                </Typography>
            </Paper>
        </main>
    )
};

export const submitButton = (state, classes) => {
    if (state.role !== 'user') {
        return (
            <Paper className={classes.paper} elevation={1}>
                <Button
                    type="submit"
                    value="Submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}>
                    Submit
                </Button>
            </Paper>
        )
    }
};

export const titleBar = (state, classes, title) => {
    return (
        <Paper className={classes.paper}>
            <Typography variant="h5">
                {title}
            </Typography>
        </Paper>
    )
};
export const fetchStage = (projectId, stageId) => {
    return fetch(`${backendURL}projects/${projectId}/stages/${stageId}/`, {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Token ' + UserState.token
        }),
    })
        .then(res => {
            return res.json()
        })
        .then(
            (response) => {
                let result = {project: projectId, is_loaded: true};
                if (response['data']) {
                    let data = response['data'];
                    for (let field in data) {
                        if (data[field] === null) {
                            delete data[field]
                        }
                    }
                    result = {...result, ...data};
                }
                return result;
            })
};

export const fetchUsers = () => {
    return fetch(`${backendURL}users/`, {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Token ' + UserState.token
        }),
    })
        .then(res => res.json())
        .then(
            (response) => {
                return response.map(user => (
                    {value: user.id, label: user.get_full_name}
                ));
            })
};


const prepareBody = (state) => {
    let body = {...state};
    // Fields defined as numeric in database that cannot be set to ''
    // and must be nullified before sending to the server
    // https://github.com/encode/django-rest-framework/issues/5749
    for (let field in body) {
        if (body[field] === '') {
            body[field] = null
        }
    }
    return JSON.stringify(body)
};

export const handleSubmit = (event, state) => {
    event.preventDefault();
    let has_errors = false;
    let result = {};
    return fetch(`${backendURL}${state.stage_name}/${state.id === '' ? '' : state.id + '/'}`, {
        method: state.id === '' ? 'POST' : 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + UserState.token
        },
        body: prepareBody(state)
    }).then((response) => {
        has_errors = !response.ok;
        return response.json()
    }).then((responseData) => {
        result.error_messages = has_errors ? responseData : {};
        return responseData;
    }).then((responseData) => {
        if (!has_errors) {
            result = {...result, ...responseData};
            toast.success('Success!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            if (responseData.detail) {
                toast.error(responseData.detail, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        }
        return result;
    });
};

export const fetchProject = (projectId, projectDispatch) => {
    return fetch(`${backendURL}projects/${projectId}/`, {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Token ' + UserState.token
        }),
    })
        .then(res => res.json())
        .then(result => {
                const payload = {
                    projectId: result.id,
                    projectName: result.name,
                    projectStage: result.stage,
                    projectStageId: result.stage_id,
                    projectCode: result.project_code,
                    projectAccount: result.account,
                    projectAccountId: result.account_id,
                    projectStageStatus: result.stage_status,
                    projectAmount: parseFloat(result.amount || 0),
                    projectCurrency: result.currency,
                    projectFinancialsAtRisk: result.financials_at_risk
                };
                projectDispatch({type: 'set', payload: payload});

                // Set the page title once the page has received new data.
                document.title = result.name;
            }
        );
}
