import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import Typography from "@material-ui/core/Typography/index";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

const Negotiation = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 4,
        stage_name: 'negotiation',
        objectives_established: false,
        customer_objectives: '',
        partner_objectives: '',
        datatonic_objectives: ''
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const handleSubmit = (event) => {
        shared.handleSubmit(event, state).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const classes = useStyles();
    const {
        is_loaded,
        customer_objectives,
        partner_objectives,
        datatonic_objectives,
        objectives_established,
        error_messages
    } = state;

    if (!is_loaded) {
        return shared.loader(classes)
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>
                    { shared.titleBar(state, classes, 'Negotiation') }
                    <br/>

                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="subtitle1">
                            Finalise negotiation
                        </Typography>

                        <TextField
                            id="customer_objectives"
                            name={"customer_objectives"}
                            label={"customer_objectives"}
                            helperText={error_messages['customer_objectives'] !== undefined ? error_messages['customer_objectives'] : ""}
                            error={error_messages['customer_objectives'] !== undefined}
                            fullWidth
                            value={customer_objectives}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            id="partner_objectives"
                            name={"partner_objectives"}
                            label={"partner_objectives"}
                            helperText={error_messages['partner_objectives'] !== undefined ? error_messages['partner_objectives'] : ""}
                            error={error_messages['partner_objectives'] !== undefined}
                            fullWidth
                            value={partner_objectives}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            id="datatonic_objectives"
                            name={"datatonic_objectives"}
                            label="datatonic_objectives"
                            helperText={error_messages['datatonic_objectives'] !== undefined ? error_messages['datatonic_objectives'] : ""}
                            error={error_messages['datatonic_objectives'] !== undefined}
                            fullWidth
                            value={datatonic_objectives}
                            onChange={onChange}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"objectives_established"}
                                    checked={objectives_established}
                                    onChange={onChange}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Customer, Partner and Datatonic objectives signed off"
                        />
                    </Paper>

                    <br/>

                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
};

export default Negotiation;
