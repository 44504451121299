import React, {useContext} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from '@material-ui/icons/AccountCircle';
import UserState from "../states/UserState";
import ProjectContext from '../store';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {currentEnv} from "../context/host"

const useStyles = makeStyles((theme) => createStyles({
    grow: {
        color: 'white',
        flexGrow: 1,
    },
    appBar: {
        height: "48px",
        zIndex: theme.zIndex.drawer + 1,
        justify: 'space-between'
    }
}));

const Header = () => {
    const user = UserState.user;
    let title = 'Genesis'
    const env = currentEnv()
    if (env === 'staging') {
        title = 'Genesis Staging'
    }
    else if (env === 'local') {
        title = 'Genesis Local'
    }

    const [state, dispatch] = useContext(ProjectContext);

    const handleLogout = () => {
        UserState.unsetUser();
        dispatch({type: 'unset'});
        window.location.href = '/';
    };

    const handleProjectUnselect = () => {
        dispatch({type: 'unset'});
    };

    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar style={{minHeight: "48px"}}>
                <Typography variant="h6" className={classes.grow}>
                    <Link onClick={handleProjectUnselect}
                          style={{textDecoration: 'none', color: 'white'}}
                          to={"/"}>{title}</Link>
                </Typography>

                <Typography variant="h6" className={classes.grow}>
                    {state.projectCode && `[${state.projectCode}] `}
                    {state.projectAccount && state.projectName && <Link style={{color: 'white'}}
                                                                        onClick={handleProjectUnselect}
                                                                        to={`/accounts/${state.projectAccountId}`}>{state.projectAccount}</Link>}
                    {state.projectAccount && !state.projectName && `${state.projectAccount} - `}
                    {state.projectName && ` - ${state.projectName}`}
                    {state.projectAccount && !state.projectName && <Link style={{color: 'white'}}
                                                                         onClick={handleProjectUnselect}
                                                                         to={`/?account=${state.projectAccount}`}>Projects</Link>}
                </Typography>

                <div>
                    <IconButton color={"inherit"}>
                        <AccountCircle/>
                        <Typography color={"inherit"}>
                            {user}
                        </Typography>
                    </IconButton>
                    {user &&
                    <IconButton aria-label="exit" color={"inherit"} onClick={handleLogout}>
                        <ExitToAppIcon/>
                    </IconButton>}
                </div>

            </Toolbar>
        </AppBar>
    )
};

export default Header;
