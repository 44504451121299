export const STAGES = [
    {
        "value": 1,
        "label": "Qualification",
        "order": 1
    },
    {
        "value": 2,
        "label": "Analysis",
        "order": 2
    },
    {
        "value": 3,
        "label": "Proposal",
        "order": 3
    },
    {
        "value": 4,
        "label": "Negotiation",
        "order": 4
    },
    {
        "value": 5,
        "label": "Closed-Won",
        "order": 5
    },
    {
        "value": 6,
        "label": "Kick-Off",
        "order": 6
    },
    {
        "value": 7,
        "label": "In-Flight",
        "order": 7
    },
    {
        "value": 8,
        "label": "Close-Out",
        "order": 8
    },
    {
        "value": 9,
        "label": "Lost",
        "order": 9
    },
    {
        "value": 10,
        "label": "Parked",
        "order": 10
    },
    {
        "value": 11,
        "label": "Delivered",
        "order": 11
    }
];