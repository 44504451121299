import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import UserState from "../../states/UserState";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from 'react-select';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import NumberFormat from 'react-number-format';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";

const fundingTypes = ["Customer", "Google", "Intel", "Other"];
const currencies = ['USD', 'GBP', 'EUR', 'SEK', 'CHF'];
const fundingOptions = ['PSF', 'PO', 'Investment'];

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
    },
    inputLabel: {
        fontSize: 12
    },
    inputFunding: {
        marginLeft: theme.spacing(1),
    },
    inputNumberFunding: {
        width: 100,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    select: {
        marginTop: theme.spacing(3),
        height: 30,
        width: 130
    },
    selectCurrency: {
        marginTop: theme.spacing(3),
        height: 30,
        width: 100
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

const ClosedWon = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 5,
        stage_name: 'closedwon',
        swo_proposal_signed_by_the_customer: false,
        partner_funding_formally_signed_off: false,
        project_is_staffed_and_roles_and_responsibilities_agreed: false,
        delivery_system_is_setup: false,
        invoicing_plan_established_and_agreed: false,
        xero_project_created: false,
        xero_invoices_scheduled_for_approval: false,
        funding_type: [],
        funding_details: [],
        submit: false
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
    };

    useEffect(() => handleSubmit(), [state.submit]);
    const handleSubmit = () => {
        if (state.submit) {
            shared.handleSubmit(state.event, state).then((response) => {
                setState(state => ({...state, ...response, submit: false}));
            })
        }
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const handleClosedWonSubmit = (e) => {
        e.preventDefault();
        const funding_details = [...state.funding_details.filter(item => item.reference !== '' && item.funding_party !== '' && item.amount !== '')];
        setState(state => ({...state, funding_details: funding_details, event: e, submit: true}));
    };

    const onChangeFundingType = (e) => {
        e.preventDefault();
        const fundingType = 'funding_type';
        let values = [...state[fundingType]];
        if (e.target.checked) {
            values.push(e.target.name);
        } else {
            values = state[fundingType].filter(item => item !== e.target.name)
        }
        const data = {[fundingType]: values};
        setState(state => ({...state, ...data}));
    };

    const onChangeFunding = (index, e) => {
        e.preventDefault();
        let funding_details = [...state.funding_details];
        funding_details[index][e.target.name] = e.target.value;
        setState(state => ({...state, funding_details: funding_details}));
    };

    const onChangeFundingSelect = (name, index, item) => {
        let funding_details = [...state.funding_details];
        funding_details[index][name] = item.value;
        setState(state => ({...state, funding_details: funding_details}));
    };

    const onChangeFundingAmount = (index, values) => {
        let funding_details = [...state.funding_details];
        funding_details[index]['amount'] = values.value;
        setState(state => ({...state, funding_details: funding_details}));
    };

    const appendRow = (e) => {
        e.preventDefault();
        const funding_details = [...state.funding_details, {
            type: 'PSF',
            reference: '',
            funding_party: '',
            amount: '',
            currency: 'USD'
        }];
        setState(state => ({...state, funding_details: funding_details}));
    };

    const deleteRow = (e) => {
        e.preventDefault();
        const funding_details = [...state.funding_details.slice(0, -1)];
        setState(state => ({...state, funding_details: funding_details}));
    };

    const classes = useStyles();
    const {
        is_loaded,
        swo_proposal_signed_by_the_customer,
        partner_funding_formally_signed_off,
        project_is_staffed_and_roles_and_responsibilities_agreed,
        delivery_system_is_setup,
        invoicing_plan_established_and_agreed,
        xero_project_created,
        xero_invoices_scheduled_for_approval,
        funding_type,
        funding_details,
    } = state;

    if (!is_loaded) {
        return shared.loader(classes)
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleClosedWonSubmit}>
                    <div className={classes.toolbar}/>
                   { shared.titleBar(state, classes, 'Closed-Won') }
                    <br/>
                    <Paper className={classes.paper} elevation={1}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"swo_proposal_signed_by_the_customer"}
                                    checked={swo_proposal_signed_by_the_customer}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="SoW/Proposal signed by the customer"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"partner_funding_formally_signed_off"}
                                    checked={partner_funding_formally_signed_off}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Partner funding formally signed-off"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"project_is_staffed_and_roles_and_responsibilities_agreed"}
                                    checked={project_is_staffed_and_roles_and_responsibilities_agreed}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Project is staffed and roles&responsibilities agreed"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"delivery_system_is_setup"}
                                    checked={delivery_system_is_setup}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Delivery system is setup (Drive, Team Email, Slack Channel, ...)"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"invoicing_plan_established_and_agreed"}
                                    checked={invoicing_plan_established_and_agreed}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Invoicing plan established and agreed"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"xero_project_created"}
                                    checked={xero_project_created}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Xero project created"
                        />

                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"xero_invoices_scheduled_for_approval"}
                                    checked={xero_invoices_scheduled_for_approval}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Xero invoices scheduled for approval"
                        />
                        <br/>
                        <InputLabel className={classes.inputLabel}>
                            funding type
                        </InputLabel>
                        <FormControl component="fieldset" fullWidth={true} style={{marginLeft: "20px"}}>
                            <FormGroup row>
                                {fundingTypes.map((fundingType, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                name={fundingType}
                                                checked={funding_type !== null && funding_type.includes(fundingType)}
                                                onChange={onChangeFundingType}
                                                disabled={UserState.role === 'user'}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                color={"primary"}
                                            />
                                        }
                                        label={fundingType}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                        <br/>

                        {funding_details != null && funding_details.map((funding_detail, index) => (
                            <FormGroup key={"funding_detail_" + index} row>
                                <Select
                                    key={"type_" + index}
                                    name={"type"}
                                    label="type"
                                    isDisabled={UserState.role === 'user'}
                                    className={classes.select}
                                    defaultValue={{value: funding_detail.type, label: funding_detail.type}}
                                    onChange={(item) => onChangeFundingSelect('type', index, item)}
                                    options={fundingOptions.map(item => ({value: item, label: item}))}
                                />
                                <TextField
                                    key={"reference_" + index}
                                    name={"reference"}
                                    disabled={UserState.role === 'user'}
                                    value={funding_detail.reference}
                                    margin="normal"
                                    className={classes.inputFunding}
                                    onChange={(e) => onChangeFunding(index, e)}
                                    label="reference"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    key={"funding_party_" + index}
                                    name={"funding_party"}
                                    label="funding party"
                                    disabled={UserState.role === 'user'}
                                    value={funding_detail.funding_party}
                                    margin="normal"
                                    className={classes.inputFunding}
                                    onChange={(e) => onChangeFunding(index, e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    key={"amount_" + index}
                                    onValueChange={(values) => onChangeFundingAmount(index, values)}
                                    name={"amount"}
                                    label="amount"
                                    value={funding_detail.amount}
                                    className={classes.inputNumberFunding}
                                    disabled={UserState.role === 'user'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Select
                                    key={"currency_" + index}
                                    name="currency"
                                    isDisabled={UserState.role === 'user'}
                                    className={classes.selectCurrency}
                                    defaultValue={{value: funding_detail.currency, label: funding_detail.currency}}
                                    onChange={(item) => onChangeFundingSelect('currency', index, item)}
                                    options={currencies.map(item => ({value: item, label: item}))}
                                />

                                {UserState.role !== 'user' && index + 1 === funding_details.length &&
                                <IconButton aria-label="delete" className={classes.margin} onClick={deleteRow}>
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>}
                            </FormGroup>
                        ))}
                        <br/>
                        {UserState.role !== 'user' &&
                        <Button variant="outlined" size="small" color="primary" onClick={appendRow}>
                            Add funding details
                        </Button>}
                        <br/>
                    </Paper>

                    <br/>
                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
};

export default ClosedWon;
