import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {getBackendURL} from "../context/host"
import UserState from "../states/UserState";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {toast} from "react-toastify";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";
import {STAGES} from "../constants";
import Select from 'react-select';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import ProjectContext from "../store";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import chroma from 'chroma-js';
import NumberFormat from "react-number-format";

const backendURL = getBackendURL();
const availableProjectTypes = ["Training", "Workshop", "ML Strategy Workshop", "PoC", "PoV", "ML At Scale", "Data & Analytics Platform of the Future", "Plan & Accelerate Innovation", "Looker", "Other"];
const projectStatusOptions = [
    {value: "On Track", label: "On Track", color: "#36B37E"},
    {value: "At Risk", label: "At Risk", color: "#FFC400"},
    {value: "Overbudget", label: "Overbudget", color: "#FF5630"}
]

// Based on https://react-select.com/styles
const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    menu: base => ({...base, zIndex: 100}),
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};


const useStyles = makeStyles((theme) => createStyles({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 500,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        minHeight: 900
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    inputLabel: {
        fontSize: 12
    },
    select: {
        marginTop: 5,
        marginBottom: 5,
        width: 400,
    },
    selectSmall:{
        marginTop: 5,
        marginBottom: 5,
        width: 200,
    },
    input: {
        width: 400,
    },
    inputNumber: {
        width: 200,
        marginTop: theme.spacing(2)
    },

    inputNumberSmall: {
        width: 150,
        marginTop: theme.spacing(2)
    },

    inputRight: {
        marginLeft: theme.spacing(1)
    },
    linkRight: {
        float: 'right'
    },
    link: {
        marginTop: theme.spacing(3)
    },
    chips: {
        '& > *': {
            margin: theme.spacing(0.5),
            color: '#AAAAAA'
        },
    },
}));


const Project = props => {
    const {match: {params}} = props;
    const [, projectDispatch] = useContext(ProjectContext);

    const projectState = {
        id: '',
        office: '',
        stages: STAGES,
        account_id: '',
        account: '',
        stage_id: '',
        name: '',
        competencies: [],
        partners: [],
        project_code: '',
        project_type: '',
        amount: '',
        forecast_probability: '',
        forecast_type: '',
        forecast_counter: '',
        forecast_amount: '',
        currency: '',
        commercial_lead_id: '',
        engagement_lead_id: '',
        project_manager_id: '',
        technical_lead_id: '',
        start_date: '',
        end_date_estimated: '',
        end_date_actual: '',
        budget_hours_planned: '',
        budget_hours_actuals: '',
        close_date: '',
        description_url: '',
        team_size: '',
        team_members: [],
        team_interested: [],
        project_updates: {},
        project_updates_text: '',
        project_updates_history: [],
        overbudget_details: '',
        users: [],
        project_status: '',
        scope_delivered: '',
        error: null,
        isLoaded: false,
        interested: false,
        stage_status: {},
        error_messages: {},
        openDialog: false
    };
    const [state, setState] = useState(projectState);

    useEffect(() => fetchProject(), []);

    const fetchProject = () => {
        fetch(`${backendURL}projects/${params.id}/`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    const responseState = {
                        competencies: (result.competencies == null ? [] : result.competencies),
                        partners: (result.partners == null ? [] : result.partners),
                        team_members: (result.team_members == null ? [] : result.team_members),
                        team_interested: (result.team_interested == null ? [] : result.team_interested),
                        project_updates_text: (result.project_updates == null ? '' : result.project_updates['text']),
                        isLoaded: true
                    };
                    const fields = ['id', 'office', 'account_id', 'account', 'stage_id', 'name',
                        'project_code', 'project_type', 'amount', 'currency', 'forecast_probability',
                        'forecast_type', 'forecast_counter', 'forecast_amount',
                        'commercial_lead_id', 'engagement_lead_id', 'project_manager_id', 'technical_lead_id',
                        'start_date', 'end_date_estimated', 'end_date_actual', 'close_date',
                        'budget_hours_planned', 'budget_hours_actuals',
                        'description_url', 'team_size', 'project_updates', 'stage_status', 'project_status',
                        'scope_delivered', 'overbudget_details',
                    ];
                    for (let field of fields) {
                        if (result[field] !== null) {
                            responseState[field] = result[field]
                        }
                    }
                    setState(state => ({...state, ...responseState}));
                    setState(state => ({...state, interested: state.team_interested.includes(UserState.user)}));

                    const payload = {
                        projectId: result.id,
                        projectName: result.name,
                        projectStage: result.stage,
                        projectStageId: result.stage_id,
                        projectCode: result.project_code,
                        projectAccount: result.account,
                        projectAccountId: result.account_id,
                        projectStageStatus: result.stage_status,
                        projectAmount: parseFloat(result.amount || 0),
                        projectCurrency: result.currency,
                        projectFinancialsAtRisk: result.financials_at_risk
                    };
                    projectDispatch({type: 'set', payload: payload});

                    // Set the page title once the page has received new data.
                    document.title = result.name;
                },
                (error) => {
                    setState(state => ({...state, ...error, isLoaded: true}));
                }
            )
            .catch(error => {
                setState(state => ({...state, ...error, isLoaded: true}));
            });

        fetch(`${backendURL}users/`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    const users = result.map(user => (
                        {value: user.id, label: user.get_full_name}
                    ));

                    setState(state => ({...state, users: users}));
                }
            );
        fetch(`${backendURL}projects/${params.id}/updates`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setState(state => ({...state, project_updates_history: result}));
                }
            );
    };

    const onChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const data = {[e.target.name]: value};
        setState(state => ({...state, ...data}));
    };

    const onChangeSelect = name => item => {
        // https://github.com/JedWatson/react-select/issues/1631#issuecomment-418844561
        const value = item ? item.value : null;
        setState(state => ({...state, [name]: value}));
    };

    const onChangeNumberField = (name, value) => {
        setState(state => ({...state, [name]: value}));
    };

    const prepareBody = () => {
        let body = {...state};
        // Fields defined as numeric in database that cannot be set to ''
        // and must be nullified before sending to the server
        // https://github.com/encode/django-rest-framework/issues/5749
        for (let field in body) {
            if (body[field] === '') {
                body[field] = null
            }
        }
        return JSON.stringify(body)
    };

    const handleSubmit = (event) => {
        let has_errors = false;
        event.preventDefault();
        fetch(`${backendURL}projects/${state.id}/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + UserState.token
            },
            body: prepareBody()
        })
            .then((response) => {
                has_errors = !response.ok;
                return response.json()
            })
            .then((responseData) => {
                const error_messages = has_errors ? responseData : {};
                setState(state => ({...state, error_messages: error_messages}));
                return responseData;
            })
            .then((responseData) => {
                if (!has_errors) {
                    toast.success('Success!', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    const payload = {
                        projectStage: responseData.stage,
                        projectStageId: responseData.stage_id,
                        projectStageStatus: responseData.stage_status
                    };
                    projectDispatch({type: 'set', payload: payload});
                } else if (responseData.detail) {
                    toast.error(responseData.detail, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

            });
    };

    const handleClickOpen = () => {
        setState(state => ({...state, openDialog: true}));
    };

    const handleClose = () => {
        setState(state => ({...state, openDialog: false}));
    };

    const classes = useStyles();
    const {
        office,
        stages,
        account,
        stage_id,
        name,
        project_type,
        project_code,
        amount,
        forecast_probability,
        forecast_type,
        // forecast_counter,
        forecast_amount,
        currency,
        start_date,
        end_date_estimated,
        end_date_actual,
        budget_hours_planned,
        budget_hours_actuals,
        close_date,
        description_url,
        team_size,
        team_members,
        team_interested,
        project_updates,
        project_updates_text,
        project_updates_history,
        interested,
        users,
        project_status,
        scope_delivered,
        overbudget_details,
        error,
        isLoaded,
        error_messages,
        openDialog
    } = state;

    if (error) {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar}/>

                <Typography variant="h5">
                    Error: {error.message}
                </Typography>
            </main>
        );
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <main className={classes.content}>

                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>

                    <Grid container spacing={3}>
                        <Grid item xs>

                            <Paper className={classes.paper} elevation={1}>
                                <TextField
                                    id="office"
                                    name={"office"}
                                    label="office"
                                    disabled={true}
                                    className={classes.input}
                                    value={office}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>

                                <TextField
                                    id="account"
                                    name={"account"}
                                    label="account"
                                    disabled={true}
                                    className={classes.input}
                                    value={account}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>

                                <TextField
                                    id="name"
                                    name={"name"}
                                    label="name"
                                    disabled={true}
                                    className={classes.input}
                                    value={name}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    name="amount"
                                    label="amount"
                                    prefix={`${currency} `}
                                    value={amount}
                                    className={classes.inputNumber}
                                    disabled={true}
                                />
                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    name="forecast_amount"
                                    label="forecast amount"
                                    prefix={`${currency} `}
                                    value={forecast_amount || 0}
                                    className={`${classes.inputNumber} ${classes.inputRight}`}
                                    disabled={true}
                                />
                                <br/>

                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    name="probability"
                                    label="probability"
                                    suffix="%"
                                    value={forecast_probability || 0}
                                    className={`${classes.inputNumber} ${classes.inputRight}`}
                                    disabled={true}
                                />
                                 <TextField
                                    id="forecast_type"
                                    name={"forecast_type"}
                                    label="forecast type"
                                    disabled={true}
                                    value={forecast_type}
                                    className={classes.inputRight}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>

                                {state.competencies.length > 0 &&
                                <div className={classes.chips}>
                                    <br/>
                                    <label>function / practice</label>
                                    <br/>
                                    {state.competencies.map((competency) =>
                                        <Chip variant="outlined" key={competency} label={competency}/>
                                    )}
                                </div>
                                }

                                {state.partners.length > 0 &&
                                <div className={classes.chips}>
                                    <br/>
                                    <label>partners</label>
                                    <br/>
                                    {state.partners.map((partner) =>
                                        <Chip variant="outlined" key={partner} label={partner}/>
                                    )}
                                </div>
                                }
                                <br/>
                                <TextField
                                    type="number"
                                    id="budget_hours_planned"
                                    name={"budget_hours_planned"}
                                    label="budget estimated (h)"
                                    disabled={true}
                                    value={budget_hours_planned}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    type="number"
                                    id="budget_hours_actuals"
                                    name={"budget_hours_actuals"}
                                    label="budget actual (h)"
                                    disabled={true}
                                    value={budget_hours_actuals}
                                    className={classes.input_right}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                <TextField
                                    id="project_code"
                                    name={"project_code"}
                                    label="project code"
                                    disabled={true}
                                    value={project_code}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {team_members.length > 0 &&
                                <div className={classes.chips}>
                                    <br/>
                                    <label>delivery team members ({team_size}):</label>
                                    <br/>
                                    {team_members.map((member) =>
                                        <Chip variant="outlined" key={member} label={member}/>
                                    )}
                                </div>
                                }
                                {team_interested.length > 0 &&
                                <div className={classes.chips}>
                                    <br/>
                                    <label>Interested in working on this project:</label>
                                    <br/>
                                    {team_interested.map((member) =>
                                        <Chip variant="outlined" key={member} label={member}/>
                                    )}
                                </div>
                                }
                            </Paper>
                        </Grid>

                        <Grid item xs>
                            <Paper className={classes.paper} elevation={1}>
                                <TextField
                                    id="description_url"
                                    name={"description_url"}
                                    label="project description"
                                    disabled={UserState.role === 'user'}
                                    helperText={error_messages['description_url'] !== undefined ? error_messages['description_url'] : "insert Customer Overview slide deck link"}
                                    error={error_messages['description_url'] !== undefined}
                                    onChange={onChange}
                                    className={classes.input}
                                    value={description_url}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {description_url &&
                                <div style={{display: "inline-flex", marginLeft: "15px"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        className={classes.link}
                                        href={description_url}
                                        target="_blank">
                                        Open
                                    </Button>
                                </div>
                                }
                                <br/>
                                <br/>
                                <InputLabel
                                    className={classes.inputLabel}
                                    label={
                                        error_messages['stage_id'] !== undefined ?
                                            "stage_id: " + error_messages['stage_id'] :
                                            "stage_id"
                                    }
                                    error={error_messages['stage_id'] !== undefined}
                                >
                                    stage
                                </InputLabel>
                                <Select
                                    id="stage_id"
                                    name={"stage_id"}
                                    label="stage"
                                    defaultValue={stage_id}
                                    value={stages.find(stage => {
                                        return stage.value === stage_id
                                    })}
                                    isDisabled={UserState.role === 'user'}
                                    onChange={onChangeSelect('stage_id')}
                                    className={classes.select}
                                    options={stages}
                                />
                                <br/>

                                <InputLabel
                                    className={classes.inputLabel}
                                    label={
                                        error_messages['project_type'] !== undefined ?
                                            "project_type: " + error_messages['stage_id'] :
                                            "project_type"
                                    }
                                    error={error_messages['project_type'] !== undefined}
                                >
                                    project type
                                </InputLabel>
                                <Select
                                    id="project_type"
                                    name={"project_type"}
                                    label="project type"
                                    value={{value: project_type, label: project_type}}
                                    isClearable={true}
                                    isDisabled={UserState.role === 'user'}
                                    onChange={onChangeSelect('project_type')}
                                    className={classes.select}
                                    options={availableProjectTypes.map(item => (
                                        {value: item, label: item}
                                    ))}
                                />
                                <br/>

                                { ['commercial_lead_id', 'engagement_lead_id', 'project_manager_id', 'technical_lead_id'].map((member) =>
                                    <React.Fragment  key={member}>
                                        <InputLabel
                                            className={classes.inputLabel}
                                            label={error_messages[member] !== undefined ? member + ": " + error_messages[member] : member }
                                            error={error_messages[member] !== undefined}
                                            >
                                            {member.replace('_id', '').replace('_', ' ')}
                                        </InputLabel>
                                        <Select
                                            id={member}
                                            name={member}
                                            label={member}
                                            value={users.find(user => {
                                            return user.value === state[member]
                                            })}
                                            isClearable={true}
                                            isDisabled={UserState.role === 'user'}
                                            onChange={onChangeSelect(member)}
                                            className={classes.select}
                                            options={users}
                                        />
                                        <br/>
                                    </React.Fragment>
                                )}
                                <TextField
                                    type="date"
                                    id="start_date"
                                    name={"start_date"}
                                    label="start date"
                                    disabled={UserState.role !== 'superuser'}
                                    helperText={error_messages['start_date'] !== undefined ? error_messages['start_date'] : ""}
                                    error={error_messages['start_date'] !== undefined}
                                    onChange={onChange}
                                    value={start_date}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    type="date"
                                    id="close_date"
                                    name={"close_date"}
                                    label="close date"
                                    disabled={UserState.role === 'user'}
                                    helperText={error_messages['close_date'] !== undefined ? error_messages['close_date'] : ""}
                                    error={error_messages['close_date'] !== undefined}
                                    onChange={onChange}
                                    className={classes.inputRight}
                                    value={close_date}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                <TextField
                                    type="date"
                                    id="end_date_estimated"
                                    name={"end_date_estimated"}
                                    label="end date estimated"
                                    disabled={UserState.role !== 'superuser'}
                                    helperText={error_messages['end_date_estimated'] !== undefined ? error_messages['end_date_estimated'] : ""}
                                    error={error_messages['end_date_estimated'] !== undefined}
                                    onChange={onChange}
                                    value={end_date_estimated}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    type="date"
                                    id="end_date_actual"
                                    name={"end_date_actual"}
                                    label="end date actual"
                                    disabled={UserState.role === 'user'}
                                    helperText={error_messages['end_date_actual'] !== undefined ? error_messages['end_date_actual'] : ""}
                                    error={error_messages['end_date_actual'] !== undefined}
                                    onChange={onChange}
                                    className={classes.inputRight}
                                    value={end_date_actual}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br/>
                                {!team_members.includes(UserState.user) && <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={"interested"}
                                                checked={interested}
                                                onChange={onChange}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                color={"primary"}
                                            />
                                        }
                                        label="I'm interested in working on this project"
                                    />
                                </div>}

                                { stage_id === 7 &&
                                <React.Fragment>
                                    <InputLabel
                                        className={classes.inputLabel}
                                        label={
                                            error_messages['project_status'] !== undefined ?
                                                "project_status: " + error_messages['stage_id'] :
                                                "project_status"
                                        }
                                        error={error_messages['project_status'] !== undefined}
                                    >
                                        Project status
                                    </InputLabel>
                                    <Select
                                        id="project_status"
                                        name={"project_status"}
                                        value={projectStatusOptions.find(status => {
                                            return status.value === project_status
                                        })}
                                        isDisabled={UserState.role === 'user'}
                                        onChange={onChangeSelect('project_status')}
                                        className={classes.selectSmall}
                                        options={projectStatusOptions}
                                        styles={colourStyles}
                                    />
                                    <NumberFormat
                                        customInput={TextField}
                                        thousandSeparator={true}
                                        name="scope_delivered"
                                        label="scope delivered"
                                        suffix="%"
                                        value={scope_delivered || 0}
                                        className={classes.inputNumberSmall}
                                        helperText={error_messages['scope_delivered'] !== undefined ? error_messages['scope_delivered'] : ""}
                                        error={error_messages['scope_delivered'] !== undefined}
                                        disabled={UserState.role === 'user'}
                                        isAllowed={values => {
                                            const {formattedValue, floatValue} = values
                                            if (floatValue == null) {
                                                return formattedValue === ''
                                            } else {
                                                return (floatValue <= 100 && floatValue >= 0)
                                            }
                                        }}
                                        onValueChange={(values) => onChangeNumberField('scope_delivered', values.floatValue)}

                                    />
                                </React.Fragment>}
                                <br/>

                                <TextField
                                    id="project_updates_text"
                                    name={"project_updates_text"}
                                    label="project updates"
                                    helperText={
                                        <React.Fragment>
                                            {project_updates['user'] && `Updated by ${project_updates['user']} on ${project_updates['timestamp']}`}
                                            {project_updates['user'] &&
                                            <Link href="#" onClick={handleClickOpen} className={classes.linkRight}>
                                                updates history
                                            </Link>}
                                        </React.Fragment>
                                    }
                                    error={error_messages['project_updates'] !== undefined}
                                    fullWidth
                                    value={project_updates_text}
                                    onChange={onChange}
                                    margin="normal"
                                    multiline={true}
                                    rows={2}
                                    rowsMax={20}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Dialog
                                    open={openDialog}
                                    onClose={handleClose}
                                    scroll='paper'
                                    aria-labelledby="scroll-dialog-title"
                                    aria-describedby="scroll-dialog-description"
                                >
                                    <DialogTitle id="scroll-dialog-title">Project Updates History</DialogTitle>
                                    <DialogContent dividers={scroll === 'paper'}>
                                        <DialogContentText
                                            id="scroll-dialog-description"
                                            tabIndex={-1}
                                        >
                                            <List>
                                                {project_updates_history.map((item, index) =>
                                                    <React.Fragment key={index}>
                                                        <ListItem button>
                                                            <ListItemText
                                                                primary={
                                                                    <React.Fragment>
                                                                        <Typography color="textPrimary">
                                                                            {item.timestamp}
                                                                        </Typography>
                                                                        Updated by {item.user}
                                                                    </React.Fragment>

                                                                }
                                                                secondary={item.text}
                                                            />
                                                        </ListItem>
                                                        <Divider/>
                                                    </React.Fragment>
                                                )}
                                            </List>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <br/>

                                {project_status === 'Overbudget' && <TextField
                                    id="overbudget_details"
                                    name={"overbudget_details"}
                                    label={
                                        error_messages['overbudget_details'] !== undefined ?
                                            "overbudget details: " + error_messages['datatonic_objectives'] :
                                            "overbudget details"
                                    }
                                    error={error_messages['overbudget_details'] !== undefined}
                                    fullWidth
                                    value={overbudget_details}
                                    onChange={onChange}
                                    margin="normal"
                                    multiline={true}
                                    rows={2}
                                    rowsMax={10}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />}
                                <br/>
                                <Button
                                    type="submit"
                                    value="Submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}>
                                    Submit
                                </Button>
                            </Paper>

                        </Grid>
                    </Grid>
                </form>
            </main>
        )
    }
}

export default Project;
