import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import Typography from "@material-ui/core/Typography/index";
import UserState from "../states/UserState";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {getBackendURL} from "../context/host"
import {toast} from "react-toastify";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import ProjectContext from "../store";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "react-select";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

const backendURL = getBackendURL();

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    link: {
        marginTop: theme.spacing(3),
        color: '#FFFFFF',
    },
    textField: {
        width: '400px'
    }
}));

const HARVEST_ADMIN_USERS = [
    {"value": 3028989, "label": "alvaro.silva-santisteban@datatonic.com", 'is_manager': true},
    {"value": 2105522, "label": "andrew.powell@datatonic.com", 'is_manager': true},
    {'value': 2105502, 'label': 'lennert.acke@datatonic.com', 'is_manager': true},
    {'value': 2105519, 'label': 'louis.decuypere@datatonic.com', 'is_manager': true},
];
const Systems = props => {
    const {match: {params}} = props;
    const [, projectDispatch] = useContext(ProjectContext);

    const systemsState = {
        id: '',
        stage_id: '',
        commercial_lead_id: '',
        engagement_lead_id: '',
        project_manager_id: '',
        technical_lead_id: '',
        interested: '',
        project_updates_text: '',
        harvest_id: '',
        harvest_url: '',
        pipedrive_id: '',
        pipedrive_url: '',
        xero_id: '',
        xero_url: '',
        googledrive_businessdevelopment_folder_id: '',
        googledrive_businessdevelopment_folder_url: '',
        googledrive_delivery_folder_id: '',
        googledrive_delivery_folder_url: '',
        googledrive_legal_folder_id: '',
        googledrive_legal_folder_url: '',
        budget_calculator_id: '',
        budget_calculator_url: '',
        interaction_log_id: '',
        interaction_log_url: '',
        proposal_id: '',
        proposal_url: '',
        error: null,
        is_loaded: false,
        harvest_data: {},
        harvest_users: [],
        harvest_dialog: false,
        error_messages: {}
    };
    const [state, setState] = useState(systemsState);

    useEffect(() => fetchProject(), []);

    const fetchProject = () => {
        fetch(`${backendURL}projects/${params.id}/`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    const responseState = {
                        id: result.id,
                        stage_id: result.stage_id,
                        commercial_lead_id: result.commercial_lead_id,
                        engagement_lead_id: result.engagement_lead_id,
                        project_manager_id: result.project_manager_id,
                        technical_lead_id: result.technical_lead_id,
                        project_updates_text: (result.project_updates == null ? null : result.project_updates['text']),
                        interested: (result.team_interested == null ? false : result.team_interested.includes(UserState.user)),
                        harvest_id: result.harvest_id,
                        harvest_url: result.harvest_url,
                        pipedrive_id: result.pipedrive_id,
                        pipedrive_url: result.pipedrive_url,
                        xero_id: result.xero_id,
                        xero_url: result.xero_url,
                        googledrive_businessdevelopment_folder_id: result.googledrive_businessdevelopment_folder_id,
                        googledrive_businessdevelopment_folder_url: result.googledrive_businessdevelopment_folder_url,
                        googledrive_delivery_folder_id: result.googledrive_delivery_folder_id,
                        googledrive_delivery_folder_url: result.googledrive_delivery_folder_url,
                        googledrive_legal_folder_id: result.googledrive_legal_folder_id,
                        googledrive_legal_folder_url: result.googledrive_legal_folder_url,
                        budget_calculator_id: result.budget_calculator_id,
                        budget_calculator_url: result.budget_calculator_url,
                        interaction_log_id: result.interaction_log_id,
                        interaction_log_url: result.interaction_log_url,
                        proposal_id: result.proposal_id,
                        proposal_url: result.proposal_url,
                        is_loaded: true,
                        harvest_data: {
                            client_id: result.account_harvest_id,
                            name: result.name,
                            code: result.next_project_code,
                            account_name: result.account,
                            account_code: '',
                            project_type: 'fixed_fee',
                            budget: result.budget_hours_planned,
                            notify_when_over_budget: true,
                            users: HARVEST_ADMIN_USERS
                        },
                    };
                    setState(state => ({...state, ...responseState}));

                    if (!result.harvest_id) {
                        fetchUsers();
                    }
                    const payload = {
                        projectId: result.id,
                        projectName: result.name,
                        projectStage: result.stage,
                        projectStageId: result.stage_id,
                        projectCode: result.project_code,
                        projectAccount: result.account,
                        projectAccountId: result.account_id,
                        projectStageStatus: result.stage_status,
                        projectAmount: parseFloat(result.amount || 0),
                        projectCurrency: result.currency,
                        projectFinancialsAtRisk: result.financials_at_risk
                    };
                    projectDispatch({type: 'set', payload: payload});
                },
                (error) => {
                    setState(state => ({...state, ...error, isLoaded: true}));
                }
            )
            .catch(error => {
                setState(state => ({...state, ...error, isLoaded: true}));
            });
    };

    const fetchUsers = () => {
        fetch(`${backendURL}harvest_users/`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Token ' + UserState.token
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    const adminUsers = HARVEST_ADMIN_USERS.map(user => user.value)
                    result = result.filter(user => !adminUsers.includes(user.value));

                    setState(state => ({...state, harvest_users: result}));
                })
    }

    const prepareBody = () => {
        let body = {...state};
        // Fields defined as numeric in database that cannot be set to ''
        // and must be nullified before sending to the server
        // https://github.com/encode/django-rest-framework/issues/5749
        for (let field in body) {
            if (body[field] === '') {
                body[field] = null
            }
        }
        return JSON.stringify(body)
    };

    const handleSubmit = (event) => {
        let has_errors = false;
        event.preventDefault();
        fetch(`${backendURL}projects/${state.id}/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + UserState.token
            },
            body: prepareBody()
        }).then((response) => {
            has_errors = !response.ok;
            return response.json()
        }).then((responseData) => {
            const error_messages = has_errors ? responseData : {};
            setState(state => ({...state, error_messages: error_messages}));
            return responseData;
        }).then(() => {
            if (!has_errors) {
                toast.success('Success!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        });
    };

    const handleHarvestProjectSubmit = (event) => {
        let has_errors = false;
        event.preventDefault();
        fetch(`${backendURL}projects/${state.id}/harvest_project/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + UserState.token
            },
            body: JSON.stringify(state.harvest_data)
        }).then((response) => {
            has_errors = !response.ok;
            return response.json()
        }).then((responseData) => {
            if (!has_errors) {
                window.location.reload();
            } else {
                toast.error(responseData.error, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        });

    }

    const handleHarvestClientSubmit = (event) => {
        let has_errors = false;
        event.preventDefault();
        fetch(`${backendURL}projects/${state.id}/harvest_client/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + UserState.token
            },
            body: JSON.stringify({'account_name': state.harvest_data['account_name'], 'account_code': state.harvest_data['account_code']})
        }).then((response) => {
            has_errors = !response.ok;
            return response.json()
        }).then((responseData) => {
            if (!has_errors) {
                window.location.reload();
            } else {
                toast.error(responseData.error, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        });

    }
    const onChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const data = {[e.target.name]: value};
        setState(state => ({...state, ...data}));
    };

    const handleHarvestOpen = () => {
        setState(state => ({...state, ...{harvest_dialog: true}}));
    };

    const handleHarvestClose = () => {
        setState(state => ({...state, ...{harvest_dialog: false}}));
    };

    const handleHarvestChange = (e) => {
        let harvest_data = state.harvest_data
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        harvest_data[e.target.name] = value
        setState({...state, harvest_data: harvest_data});
    };

    const handleHarvestUserSelect = (user) => {
        let harvest_data = state.harvest_data
        harvest_data['users'].push(user)
        const harvest_users = state.harvest_users.filter(u => u.value !== user.value);
        setState({...state, harvest_data: harvest_data, harvest_users: harvest_users});
    };

    const handleHarvestRemoveUser = (e, value) => {
        e.preventDefault();
        let harvest_data = state.harvest_data
        const user = state.harvest_data['users'].find(user => user.value === value)
        harvest_data['users'] = state.harvest_data['users'].filter(user => user.value !== value);
        let harvest_users = state.harvest_users
        harvest_users.push(user)
        harvest_users.sort((a, b) => (a.label > b.label) ? 1 : -1)
        setState({...state, harvest_data: harvest_data, harvest_users: harvest_users});
    };

    const handleHarvestUserManager = (e, value) => {
        let harvest_data = state.harvest_data
        let user = harvest_data['users'].find(user => user.value === value)
        user.is_manager = e.target.checked
        setState({...state, harvest_data: harvest_data});
    };

    const classes = useStyles();
    const {error, error_messages, is_loaded, harvest_data} = state;

    const fields = ['pipedrive', 'harvest', 'xero',
        'googledrive_businessdevelopment_folder', 'googledrive_delivery_folder',
        'googledrive_legal_folder', 'budget_calculator', 'interaction_log', 'proposal'];

    if (error) {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar}/>

                <Typography variant="h5">
                    Error: {error.message}
                </Typography>
            </main>
        );
    } else if (!is_loaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.toolbar}/>

                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="h5" component="h3">
                            Systems
                        </Typography>
                    </Paper>

                    <br/>
                    <Paper className={classes.paper} elevation={1}>
                        {fields.map((field) =>
                            <div key={field}>
                                <TextField
                                    id={field + '_id'}
                                    name={field + '_id'}
                                    label={field.replace(/_/g, ' ')}
                                    disabled={field === 'pipedrive'}
                                    helperText={error_messages[field + '_id'] !== undefined ? error_messages[field + '_id'] : ""}
                                    error={error_messages[field + '_id'] !== undefined}
                                    onChange={onChange}
                                    value={state[field + '_id'] ? state[field + '_id'] : ''}
                                    margin="normal"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {state[field + '_url'] &&
                                <div style={{display: "inline-flex", marginLeft: "15px"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        className={classes.link}
                                        href={state[field + '_url']}
                                        target="_blank">
                                        Open
                                    </Button>
                                </div>
                                }
                                {field === 'harvest' && !state['harvest_url'] && UserState.role !== 'user' &&
                                <div style={{display: "inline-flex", marginLeft: "15px"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        className={classes.link}
                                        onClick={handleHarvestOpen}>
                                        {harvest_data.client_id && "Create project in Harvest"}
                                        {!harvest_data.client_id && "Create client in Harvest"}
                                    </Button>
                                    <Dialog open={state.harvest_dialog} onClose={handleHarvestClose}
                                            aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">
                                            {harvest_data.client_id && "Create project in Harvest"}
                                            {!harvest_data.client_id && "Create client in Harvest"}
                                        </DialogTitle>
                                        {!harvest_data.client_id &&
                                        <DialogContent>
                                            <TextField
                                                margin="dense"
                                                id="account_name"
                                                name="account_name"
                                                label="Account name"
                                                value={harvest_data.account_name}
                                                type="text"
                                                onChange={handleHarvestChange}
                                                fullWidth
                                                required
                                            />
                                            <TextField
                                                margin="dense"
                                                id="account_code"
                                                name="account_code"
                                                label="Account code"
                                                value={harvest_data.account_code}
                                                type="text"
                                                onChange={handleHarvestChange}
                                                required
                                            />
                                        </DialogContent>
                                        }
                                        {harvest_data.client_id &&
                                        <DialogContent>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                name="name"
                                                label="Project name"
                                                value={harvest_data.name}
                                                type="text"
                                                onChange={handleHarvestChange}
                                                fullWidth
                                                required
                                            />
                                            <TextField
                                                margin="dense"
                                                id="code"
                                                name="code"
                                                label="Project code"
                                                value={harvest_data.code}
                                                type="text"
                                                onChange={handleHarvestChange}
                                                required
                                            />
                                            <br/>
                                            <TextField
                                                margin="dense"
                                                id="budget"
                                                name="budget"
                                                label="Total budget (in h)"
                                                value={harvest_data.budget}
                                                type="number"
                                                onChange={handleHarvestChange}
                                                required
                                            />
                                            <br/>
                                            <RadioGroup row aria-label="position"
                                                        name="project_type"
                                                        defaultValue={harvest_data.project_type}
                                                        onChange={handleHarvestChange}>
                                                <FormControlLabel value="fixed_fee" control={<Radio color="primary"/>}
                                                                  label="Fixed Fee"/>
                                                <FormControlLabel value="time_material"
                                                                  control={<Radio color="primary"/>}
                                                                  label="Time & Material"/>
                                                <FormControlLabel value="non_billable"
                                                                  control={<Radio color="primary"/>}
                                                                  label="Non-billable"/>
                                            </RadioGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={harvest_data.notify_when_over_budget}
                                                        onChange={handleHarvestChange}
                                                        name="notify_when_over_budget"
                                                    />
                                                }
                                                label="Notify if project budget exceeds 80%"
                                            />

                                            <Select
                                                id="users"
                                                name="users"
                                                label="users"
                                                className={classes.select}
                                                onChange={handleHarvestUserSelect}
                                                options={state.harvest_users}
                                                placeholder="Select team members"
                                            />
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell component="td" scope="row">User</TableCell>
                                                        <TableCell component="td" scope="row">Is manager?</TableCell>
                                                        <TableCell component="td" scope="row"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {harvest_data.users != null && harvest_data.users.map((user) => (
                                                        <TableRow key={user.value}>
                                                            <TableCell component="td" scope="row">
                                                                {user.label}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                <Checkbox
                                                                    checked={user.is_manager}
                                                                    onChange={(e) => handleHarvestUserManager(e, user.value)}
                                                                    name={user.label}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                <IconButton aria-label="delete"
                                                                            className={classes.deleteButton}
                                                                            onClick={(e) => handleHarvestRemoveUser(e, user.value)}>
                                                                    <DeleteIcon fontSize="small"/>
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </DialogContent>
                                        }
                                        <DialogActions>
                                            <Button onClick={handleHarvestClose} color="primary">
                                                Cancel
                                            </Button>
                                            {harvest_data.client_id &&
                                            <Button onClick={handleHarvestProjectSubmit} color="primary">
                                                Create
                                            </Button>}
                                            {!harvest_data.client_id &&
                                            <Button onClick={handleHarvestClientSubmit} color="primary">
                                                Create
                                            </Button>}
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                }
                                <br/>
                            </div>)}

                    </Paper>

                    <br/>
                    {UserState.role !== 'user' && <Paper className={classes.paper} elevation={1}>
                        <Button
                            type="submit"
                            value="Submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}>
                            Submit
                        </Button>
                    </Paper>}
                </form>
            </main>
        )
    }
}

export default Systems;
