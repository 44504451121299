import React, {useContext, useEffect, useState} from "react";
import Paper from '@material-ui/core/Paper/index';
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography/index";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from 'react-select';
import InputLabel from "@material-ui/core/InputLabel";
import UserState from "../../states/UserState";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as shared from "./shared";
import ProjectContext from "../../store";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const supportStatusOptions = ['On Track', 'Not Started', 'Done', 'At Risk', 'Blocked'];

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    select: {
        width: 200,
        marginLeft: 10
    },
    link: {
        color: '#FFFFFF',
        marginTop: theme.spacing(3),
    },

    inputLarge: {
        width: 550,
    },
    input: {
        width: 300,
    },
    inputSmall: {
        width: 150,
    },
    inputSupport: {
        marginLeft: theme.spacing(1),
    },
    selectSupport: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        height: 30,
    },
    toolbar: theme.mixins.toolbar,
}));

const Analysis = props => {
    const {match: {params}} = props;
    const [projectState, projectDispatch] = useContext(ProjectContext);

    let stageState = {
        stage_id: 2,
        stage_name: 'analysis',
        customer_objectives: '',
        partner_objectives: '',
        datatonic_objectives: '',
        review_and_sign_nda: false,
        key_decision_makers_details: '',
        funding_details: '',
        partner_funding: 'n/a',
        pre_sales_support: [],
        users: []
    };
    stageState = {...stageState, ...shared.baseState};
    const [state, setState] = useState(stageState);

    useEffect(() => fetchStage(), []);
    useEffect(() => setProjectStageStatus(), [state.status]);

    const setProjectStageStatus = () => {
        if (state.id !== '') {
            shared.setProjectStageStatus(projectState, projectDispatch, state);
        }
    };

    const fetchStage = () => {
        if (projectState.projectName === '') {
            shared.fetchProject(params.id, projectDispatch)
        }
        shared.fetchStage(params.id, state.stage_id).then((response) => {
            setState(state => ({...state, ...response}));
        })
        shared.fetchUsers().then((response) => {
            setState(state => ({...state, users: response}));
        })
    };

    useEffect(() => handleSubmit(), [state.submit]);
    const handleSubmit = () => {
        if (state.submit) {
            shared.handleSubmit(state.event, state).then((response) => {
                setState(state => ({...state, ...response, submit: false}));
            })
        }
    };

    const onChange = (e) => {
        const data = shared.onChange(e);
        setState(state => ({...state, ...data}));
    };

    const handleAnalysisSubmit = (e) => {
        e.preventDefault();
        const pre_sales_support = [...state.pre_sales_support.filter(item => item.status !== '' || item.assigned_to.length !== 0 || item.description !== '' || item.timeline !== '')];
        setState(state => ({...state, pre_sales_support: pre_sales_support, event: e, submit: true}));
    };

    const onChangeField = (field, index, e) => {
        e.preventDefault();
        let values = [...state[field]];
        values[index][e.target.name] = e.target.value;
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const onChangeSelectMultiple = (field, name, index, items) => {
        const newItems = items ? items.map(item => item.value) : [];
        let values = [...state[field]];
        values[index][name] = newItems;
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const onChangeSelectSales = (field, name, index, item) => {
        let values = [...state[field]];
        values[index][name] = item.value;
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const onChangeSelect = name => item => {
        const value = item ? item.value : null;
        setState(state => ({...state, [name]: value}));
    };

    const appendRow = (e, field) => {
        e.preventDefault();
        const values = [...state[field], {
            status: '',
            assigned_to: [],
            timeline: '',
            description: ''
        }];
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const deleteRow = (e, field) => {
        e.preventDefault();
        const values = [...state[field].slice(0, -1)];
        const data = {[field]: values};
        setState(state => ({...state, ...data}));
    };

    const classes = useStyles();
    const {
        is_loaded,
        customer_objectives,
        partner_objectives,
        datatonic_objectives,
        review_and_sign_nda,
        key_decision_makers_details,
        funding_details,
        partner_funding,
        pre_sales_support,
        users,
        error_messages
    } = state;
    const partnerFundingOptions = [
        "n/a",
        "Client Own Funding",
        "Google Funding",
        "Joint Funding"
    ];

    if (!is_loaded) {
        return shared.loader(classes)
    } else {
        return (
            <main className={classes.content}>
                <form onSubmit={handleAnalysisSubmit}>
                    <div className={classes.toolbar}/>
                    {shared.titleBar(state, classes, 'Analysis')}
                    <br/>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="subtitle1">
                            Step 1 - Establish where we can create impact
                        </Typography>

                        <TextField
                            id="customer_objectives"
                            name={"customer_objectives"}
                            label={
                                error_messages['customer_objectives'] !== undefined ?
                                    "customer objectives: " + error_messages['customer_objectives'] :
                                    "customer objectives"
                            }
                            error={error_messages['customer_objectives'] !== undefined}
                            fullWidth
                            value={customer_objectives}
                            onChange={onChange}
                            disabled={UserState.role === 'user'}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            id="partner_objectives"
                            name={"partner_objectives"}
                            label={
                                error_messages['partner_objectives'] !== undefined ?
                                    "partner objectives: " + error_messages['partner_objectives'] :
                                    "partner objectives"
                            }
                            error={error_messages['partner_objectives'] !== undefined}
                            fullWidth
                            value={partner_objectives}
                            onChange={onChange}
                            disabled={UserState.role === 'user'}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            id="datatonic_objectives"
                            name={"datatonic_objectives"}
                            label={
                                error_messages['datatonic_objectives'] !== undefined ?
                                    "datatonic objectives: " + error_messages['datatonic_objectives'] :
                                    "datatonic objectives"
                            }
                            error={error_messages['datatonic_objectives'] !== undefined}
                            fullWidth
                            value={datatonic_objectives}
                            onChange={onChange}
                            disabled={UserState.role === 'user'}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                    </Paper>

                    <br/>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="subtitle1">
                            Step 2 - Establish legal pre-requisites
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"review_and_sign_nda"}
                                    checked={review_and_sign_nda}
                                    onChange={onChange}
                                    disabled={UserState.role === 'user'}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color={"primary"}
                                />
                            }
                            label="Review and sign NDA"
                        />
                    </Paper>

                    <br/>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="subtitle1">
                            Step 3 - Document key decision makers and influencers
                        </Typography>

                        <TextField
                            id="key_decision_makers_details"
                            name={"key_decision_makers_details"}
                            label={
                                error_messages['key_decision_makers_details'] !== undefined ?
                                    "key decision makers details: " + error_messages['key_decision_makers_details'] :
                                    "key decision makers details"
                            }
                            error={error_messages['key_decision_makers_details'] !== undefined}
                            fullWidth
                            value={key_decision_makers_details}
                            onChange={onChange}
                            disabled={UserState.role === 'user'}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Paper>

                    <br/>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="subtitle1">
                            Step 4 - Clarify partner funding
                        </Typography>

                        <br/>
                        <InputLabel className={classes.inputLabel}
                                    style={{fontSize: "12px"}}
                        >
                            Partner Funding Type
                        </InputLabel>
                        <Select
                            id="partner_funding"
                            name="partner_funding"
                            label="partner_funding"
                            value={{value: partner_funding, label: partner_funding}}
                            isDisabled={UserState.role === 'user'}
                            onChange={onChangeSelect('partner_funding')}
                            className={classes.select}
                            options={partnerFundingOptions.map(item => ({value: item, label: item}))}
                        />
                        <br/>
                        <br/>
                        <TextField
                            id="funding_details"
                            name="funding_details"
                            label={
                                error_messages['funding_details'] !== undefined ?
                                    "funding details: " + error_messages['funding_details'] :
                                    "funding details"
                            }
                            error={error_messages['funding_details'] !== undefined}
                            fullWidth
                            value={funding_details}
                            onChange={onChange}
                            disabled={UserState.role === 'user'}
                            margin="normal"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Paper>

                    <br/>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="subtitle1">
                            Step 5 - Pre-sales support
                        </Typography>

                        {pre_sales_support != null && pre_sales_support.map((item, index) => (
                            <FormGroup key={"pre_sales_support_" + index} row>
                                <Select
                                    key={"status_" + index}
                                    placeholder="status"
                                    isDisabled={UserState.role === 'user'}
                                    className={`${classes.selectSupport} ${classes.inputSmall}`}
                                    value={item.status === '' ? undefined : {value: item.status, label: item.status}}
                                    onChange={(item) => onChangeSelectSales('pre_sales_support', 'status', index, item)}
                                    options={supportStatusOptions.map(item => ({value: item, label: item}))}
                                />
                                <Select
                                    key={"assigned_to_" + index}
                                    placeholder="assigned to"
                                    isDisabled={UserState.role === 'user'}
                                    className={`${classes.selectSupport} ${classes.input}`}
                                    value={users.filter(user => {
                                        return (item.assigned_to || []).includes(user.value)
                                    })}
                                    isMulti
                                    onChange={(items) => onChangeSelectMultiple('pre_sales_support', 'assigned_to', index, items)}
                                    options={users}
                                />
                                <TextField
                                    key={"timeline_" + index}
                                    type="date"
                                    name={"timeline"}
                                    disabled={UserState.role === 'user'}
                                    value={item.timeline}
                                    margin="normal"
                                    className={`${classes.inputSupport} ${classes.inputSmall}`}
                                    onChange={(e) => onChangeField('pre_sales_support', index, e)}
                                    label="timeline"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    key={"description_" + index}
                                    name={"description"}
                                    disabled={UserState.role === 'user'}
                                    value={item.description}
                                    margin="normal"
                                    className={`${classes.inputSupport} ${classes.inputLarge}`}
                                    onChange={(e) => onChangeField('pre_sales_support', index, e)}
                                    label="description"
                                    multiline={true}
                                    rows={1}
                                    rowsMax={6}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {UserState.role !== 'user' && index + 1 === pre_sales_support.length &&
                                <IconButton aria-label="delete" className={classes.margin}
                                            onClick={(e) => deleteRow(e, 'pre_sales_support')}>
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>}
                            </FormGroup>
                        ))}

                        <br/>
                        {UserState.role !== 'user' &&
                        <Button variant="outlined" size="small" color="primary"
                                onClick={(e) => appendRow(e, 'pre_sales_support')}>
                            Add Pre-sales support
                        </Button>}
                        <br/>
                    </Paper>
                    <br/>
                    {shared.submitButton(state, classes)}
                </form>
            </main>
        )
    }
};

export default Analysis;
