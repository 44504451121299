export const initialProjectState = {
    projectId: '',
    projectName: '',
    projectStageId: '',
    projectStage: '',
    projectCode: '',
    projectAccount: '',
    projectAccountId: '',
    projectStageStatus: '',
    projectAmount: '',
    projectCurrency: '',
    projectFinancialsAtRisk: ''
};

export const projectReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return {...state, ...action.payload};
        case 'unset':
            return initialProjectState;
        default:
            throw new Error();
    }
};
